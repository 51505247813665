module Gargantext.Components.Forest.Tree.Node.Action.ManageTeam where

import Data.Array (filter, null, (:))
import Data.Either (Either(..))
import Data.Maybe (Maybe(..))
import Effect (Effect)
import Effect.Aff (runAff_)
import Gargantext.Components.Bootstrap as B
import Gargantext.Components.Forest.Tree.Node.Settings (NodeAction(..), SettingsBox(..), glyphiconNodeAction, settingsBox)
import Gargantext.Components.Forest.Tree.Node.Tools as Tools
import Gargantext.Components.GraphQL.Endpoints (deleteTeamMembership, getTeam)
import Gargantext.Components.GraphQL.Team (Team, TeamMember)
import Gargantext.Config.REST (AffRESTError)
import Gargantext.Hooks.Loader (useLoader)
import Gargantext.Prelude
import Gargantext.Sessions (Session)
import Gargantext.Types (ID, NodeType)
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.ManageTeam"

type ActionManageTeam =
  ( id :: ID
  , nodeType :: NodeType
  , session :: Session
  , tabFocusHandler :: NodeAction -> Effect Unit
  )

actionManageTeam :: R2.Component ActionManageTeam
actionManageTeam = R.createElement actionManageTeamCpt

actionManageTeamCpt :: R.Component ActionManageTeam
actionManageTeamCpt = R2.hereComponent here "actionManageTeam" hCpt
  where
  hCpt hp { id, session, tabFocusHandler } _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: loadTeam
      , path: { nodeId: id, session }
      , render: \team -> teamLayoutWrapper
          { team
          , nodeId: id
          , session
          , tabFocusHandler
          }
          []
      }

type TeamProps =
  ( nodeId :: ID
  , session :: Session
  , team :: Team
  , tabFocusHandler :: NodeAction -> Effect Unit
  )

teamLayoutWrapper :: R2.Component TeamProps
teamLayoutWrapper = R.createElement teamLayoutWrapperCpt

teamLayoutWrapperCpt :: R.Component TeamProps
teamLayoutWrapperCpt = here.component "teamLayoutWrapper" cpt
  where
  cpt { nodeId, session, team: { team_owner_username, team_members }, tabFocusHandler } _ = do
    error <- T.useBox Nothing
    team_members <- T.useBox team_members

    pure $ teamLayoutRows { nodeId, session, team_members, error, team_owner_username, tabFocusHandler }

type TeamRowProps =
  ( error :: T.Box (Maybe String)
  , nodeId :: ID
  , session :: Session
  , team_members :: T.Box (Array TeamMember)
  , team_owner_username :: String
  , tabFocusHandler :: NodeAction -> Effect Unit
  )

teamLayoutRows :: R2.Leaf TeamRowProps
teamLayoutRows = R2.leaf teamLayoutRowsCpt

teamLayoutRowsCpt :: R.Component TeamRowProps
teamLayoutRowsCpt = here.component "teamLayoutRows" cpt
  where
  cpt { error, nodeId, session, team_members, team_owner_username, tabFocusHandler } _ = do
    team_members' <- T.useLive T.unequal team_members
    error' <- T.useLive T.unequal error
    let focusOnShareTab = \_ -> tabFocusHandler Share

    pure $
      Tools.panelNoFooter
        { mError: error'
        , iconName: "users"
        , textTitle: "Manage the team"
        }
        [ if null team_members' then
            H.div { style: { margin: "10px" } }
              [ H.p {}
                  [ H.text "Your team is empty, you can send some invitations ("
                  , B.iconButton
                      { callback: focusOnShareTab
                      , title: "Invite a user"
                      -- , elevation: Level1
                      , name: "user-plus"
                      , showLabel: true
                      }
                  , H.text ")."
                  ]
              ]
          else
            R.fragment
              (makeLeader team_owner_username : (map makeTeam team_members'))
        , H.div { className: "col text-center mt-3" }
            [ B.iconButton
                { callback: focusOnShareTab
                , title: "Invite a new user"
                -- , elevation: Level1
                , name: "user-plus"
                , showLabel: true
                }
            ]
        ]

    where
    makeTeam :: TeamMember -> R.Element
    makeTeam { username, shared_folder_id } =
      H.div { className: "form-group row my-0" }
        [ H.div { className: "col-9" } [ H.text username ]
        , H.a
            { className: "text-danger col-3 text-center fa fa-times"
            , title: "Remove user from team"
            , type: "button"
            , on: { click: submit shared_folder_id }
            }
            []
        ]

    makeLeader username =
      H.div { className: "form-group row my-0" }
        [ H.div { className: "col-9" } [ H.text username ]
        , H.p { className: "col-3 text-center" } [ H.text "owner" ]
        ]

    submit sharedFolderId _ = do
      runAff_ callback $ saveDeleteTeam { session, nodeId, sharedFolderId }

    callback res = do
      case res of
        Left err -> do
          here.log2 "[callback] error forking runAff" err
          T.write_ (Just "Error forking 'runAff_'") error
        Right val -> do
          here.log2 "[callback] val" val
          case val of
            -- Left (EC_403__user_not_authorized { msg }) -> do
            --   liftEffect $ T.write_ (Just msg) error
            Left err -> do
              T.write_ (Just $ show err) error
            Right r -> do
              T.modify_ (filter (\tm -> tm.shared_folder_id /= r)) team_members
              T.write_ Nothing error

-------------------------------------------------------------

type LoadProps =
  ( session :: Session
  , nodeId :: Int
  )

loadTeam :: Record LoadProps -> AffRESTError Team
loadTeam { session, nodeId } = getTeam session nodeId

type DeleteProps =
  ( session :: Session
  , nodeId :: Int
  , sharedFolderId :: Int
  )

saveDeleteTeam ∷ Record DeleteProps -> AffRESTError Int
saveDeleteTeam { session, nodeId, sharedFolderId } = deleteTeamMembership session sharedFolderId nodeId
