// language=GLSL
const SHADER_SOURCE = /*glsl*/ `
precision highp float;

varying vec4 v_color;
varying vec2 v_diffVector;
varying float v_radius;

uniform float u_correctionRatio;

const vec4 transparent = vec4(0.0, 0.0, 0.0, 0.0);

void main(void) {
  float border = u_correctionRatio * 2.0;
  float dist = length(v_diffVector) - v_radius + border;

  // Originally, a triangle is drawn. This code paints it in such a
  // way that a circle is rendered.
  //float t = 0.0;
  //if (dist > v_border) {
  //  t = 1.0;
  //} else if (dist > 0.0) {
  //  t = dist / v_border;
  //}

  //gl_FragColor = mix(v_color, transparent, t);
  gl_FragColor = v_color;

}
`;

export default SHADER_SOURCE;