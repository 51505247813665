module Gargantext.Components.App.Store
  ( Store
  , State
  , options
  , context
  , provide
  , use
  -- legacy
  , Boxes
  ) where

import Gargantext.Prelude

import Data.Map (Map)
import Data.Map as Map
import Data.Maybe (Maybe(..))
import Data.Set as Set
import Data.Tuple (Tuple)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.Lang as Lang
import Gargantext.Components.Nodes.Lists.SidePanel as ListsSP
import Gargantext.Components.Nodes.Texts.Types as TextsT
import Gargantext.Components.Notifications.Types as Notifications
import Gargantext.Components.Themes as Themes
import Gargantext.Ends (Backend)
import Gargantext.Routes (AppRoute(Home), Tile)
import Gargantext.Sessions (Session, Sessions)
import Gargantext.Sessions as Sessions
import Gargantext.Sessions.Types (OpenNodes(..))
import Gargantext.Types (FrontendError, Handed(RightHanded), ID, SidePanelState(..))
import Gargantext.Utils.Reactix as R2
import Gargantext.Utils.Stores as Stores
import Gargantext.Utils.Toestand as T2
import Reactix as R
import Record as Record
import Toestand as T
import Unsafe.Coerce (unsafeCoerce)

here :: R2.Here
here = R2.here "Gargantext.Components.App.Store"

type Store =
  ( backend :: T.Box (Maybe Backend)
  , errors :: T.Box (Array FrontendError)
  , expandTableEdition :: T.Box Boolean
  , forestOpen :: T.Box OpenNodes
  , graphVersion :: T2.ReloadS
  , handed :: T.Box Handed
  , lang :: T.Box Lang.LandingLang
  , loginRedirect :: T.Box (Maybe (Tuple String ID))
  , pinnedTreeId :: T.Box (Map String Int)
  , reloadForest :: T2.ReloadS
  , reloadMainPage :: T2.ReloadS
  , reloadRoot :: T2.ReloadS
  , route :: T.Box AppRoute
  , session :: T.Box (Maybe Session)
  , sessions :: T.Box Sessions
  , showCorpus :: T.Box Boolean
  , showLogin :: T.Box Boolean
  , showTree :: T.Box Boolean
  , showSearch :: T.Box Boolean
  , sidePanelLists :: T.Box (Maybe (Record ListsSP.SidePanel))
  , sidePanelTexts :: T.Box (Maybe (Record TextsT.SidePanel))
  , sidePanelState :: T.Box SidePanelState
  , tasks :: T.Box GAT.Storage
  , theme :: T.Box Themes.Theme
  , tileAxisXList :: T.Box (Array (Record Tile))
  , tileAxisYList :: T.Box (Array (Record Tile))
  , wsNotification :: T.Box Notifications.WSNotification
  )

type State =
  ( backend :: Maybe Backend
  , errors :: Array FrontendError
  , expandTableEdition :: Boolean
  , forestOpen :: OpenNodes
  , graphVersion :: T2.Reload
  , handed :: Handed
  , lang :: Lang.LandingLang
  , loginRedirect :: Maybe (Tuple String ID)
  , pinnedTreeId :: Map String Int
  , reloadForest :: T2.Reload
  , reloadMainPage :: T2.Reload
  , reloadRoot :: T2.Reload
  , route :: AppRoute
  , session :: Maybe Session
  , sessions :: Sessions
  , showCorpus :: Boolean
  , showLogin :: Boolean
  , showTree :: Boolean
  , showSearch :: Boolean
  , sidePanelLists :: Maybe (Record ListsSP.SidePanel)
  , sidePanelTexts :: Maybe (Record TextsT.SidePanel)
  , sidePanelState :: SidePanelState
  , tasks :: GAT.Storage
  , theme :: Themes.Theme
  , tileAxisXList :: Array (Record Tile)
  , tileAxisYList :: Array (Record Tile)
  , wsNotification :: Notifications.WSNotification
  )

options :: Notifications.WSNotification -> Record State
options wsNotification =
  { wsNotification } `Record.merge`
    { backend: Nothing
    , errors: []
    , expandTableEdition: false
    , forestOpen: OpenNodes $ Set.empty
    , graphVersion: T2.newReload
    , handed: RightHanded
    , lang: Lang.LL_EN
    , loginRedirect: Nothing
    , pinnedTreeId: Map.empty
    , reloadForest: T2.newReload
    , reloadMainPage: T2.newReload
    , reloadRoot: T2.newReload
    , route: Home
    , session: Nothing
    , sessions: Sessions.empty
    , showCorpus: false
    , showLogin: false
    , showTree: true
    , showSearch: false
    , sidePanelLists: ListsSP.initialSidePanel
    , sidePanelTexts: TextsT.initialSidePanel
    , sidePanelState: InitialClosed
    , tasks: mempty
    , theme: Themes.defaultTheme
    , tileAxisXList: mempty
    , tileAxisYList: mempty
    }

context :: R.Context (Record Store)
context = R.createContext $ unsafeCoerce unit

provide :: Record State -> Array R.Element -> R.Element
provide values = Stores.provideStore here.name values context

use :: R.Hooks (Record Store)
use = Stores.useStore context

------------------------------------------------------

type Boxes = Record Store
