-- | This module defines a simple low-level interface to the websockets API.

module Gargantext.Utils.ZIP
  ( getFiles
  ) where

import Gargantext.Prelude

import Data.ArrayBuffer.Types (ArrayBuffer)
import Effect
import Control.Promise
import Effect.Aff (Aff)

foreign import getFilesAsync :: ArrayBuffer -> Effect (Promise (Array String))

getFiles :: ArrayBuffer -> Aff (Array String)
getFiles blob = toAffE $ getFilesAsync blob
