module Gargantext.Components.Forest.Tree.Node.Action.Upload where

import Gargantext.Utils.ZIP as ZIP
import Data.Array as A
import Data.Array.NonEmpty as DAN
import Data.Either (Either(..), fromRight', hush, note)
import Data.Eq.Generic (genericEq)
import Data.Foldable (intercalate)
import Data.Generic.Rep (class Generic)
import Data.Maybe (Maybe(..), fromJust, fromMaybe, isNothing)
import Data.Newtype (class Newtype)
import Data.Set as Set
import Data.String as DS
import Data.String.Regex as DSR
import Data.String.Regex.Flags as DSRF
import Data.Tuple (Tuple(..))
import Data.Tuple.Nested ((/\))
import Data.Traversable (traverse)
import Effect (Effect)
import Effect.Aff (Aff, launchAff)
import Effect.Class (liftEffect)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.Bootstrap as B
import Gargantext.Components.Bootstrap.Types (ComponentStatus(..))
import Gargantext.Components.Forest.Tree.Node.Action (Props)
import Gargantext.Components.Forest.Tree.Node.Action.Types (Action(..))
import Gargantext.Components.Forest.Tree.Node.Action.Upload.Types (FileFormat(..), FileType(..), UploadFileBlob(..), readUFBAsBase64, readUFBAsText, readUFBAsArrayBuffer)
import Gargantext.Components.Forest.Tree.Node.Action.Utils (loadLanguages)
import Gargantext.Components.Forest.Tree.Node.Tools as Tools
import Gargantext.Components.Lang (Lang(..), langReader)
import Gargantext.Components.ListSelection as ListSelection
import Gargantext.Components.ListSelection.Types (Selection(..))
import Gargantext.Components.ListSelection.Types as ListSelection
import Gargantext.Config.REST (AffRESTError, RESTError)
import Gargantext.Hooks.Loader (useLoader)
import Gargantext.Prelude
import Gargantext.Routes as GR
import Gargantext.Sessions (Session, postWwwUrlencoded, post)
import Gargantext.Types (ID, NodeType(..))
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Partial.Unsafe (unsafePartial, unsafeCrashWith)
import React.SyntheticEvent as E
import Reactix as R
import Reactix.DOM.HTML as H
import Record as Record
import Toestand as T
import URI.Extra.QueryPairs as QP
import Web.File.FileReader.Aff (readAsDataURL)

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.Upload"

-- UploadFile Action

-- | Action : Upload
type ActionUpload =
  ( dispatch :: Action -> Aff Unit
  , id :: ID
  , nodeType :: NodeType
  , session :: Session
  )

actionUpload :: R2.Component ActionUpload
actionUpload = R.createElement actionUploadCpt

actionUploadCpt :: R.Component ActionUpload
actionUploadCpt = here.component "actionUpload" cpt
  where
  cpt { nodeType: Corpus, dispatch, id, session } _ =
    pure $ uploadFileView { dispatch, id, nodeType: GT.Corpus, session }

  cpt { nodeType: NodeList, dispatch, id, session } _ =
    pure $ uploadTermListView { dispatch, id, nodeType: GT.NodeList, session } []

  cpt props@{ nodeType: Calc } _ = pure $ uploadFrameCalcView props []

  cpt { nodeType: Annuaire, dispatch, id, session } _ =
    pure $ uploadListView { dispatch, id, nodeType: GT.Annuaire, session }

  cpt props@{ nodeType: _ } _ = pure $ actionUploadOther props []

{-
actionUpload Annuaire id session dispatch =
  pure $ uploadFileView {dispatch, id, nodeType: Annuaire, session}
  -}

actionUploadOther :: R2.Component ActionUpload
actionUploadOther = R.createElement actionUploadOtherCpt

actionUploadOtherCpt :: R.Component ActionUpload
actionUploadOtherCpt = here.component "actionUploadOther" cpt
  where
  cpt _ _ = do
    pure $ Tools.fragmentPT $ "Soon, upload for this NodeType."

-- file upload types
data DroppedFile =
  DroppedFile
    { blob :: UploadFileBlob
    , fileType :: Maybe FileType
    , lang :: Lang
    }

derive instance Generic DroppedFile _
instance Eq DroppedFile where
  eq = genericEq

type FileHash = String

type UploadFile =
  { blob :: UploadFileBlob
  , name :: String
  }

uploadFileView :: R2.Leaf Props
uploadFileView = R2.leaf uploadFileViewCpt

uploadFileViewCpt :: R.Component Props
uploadFileViewCpt = R2.hereComponent here "uploadFileView" hCpt
  where
  hCpt hp props@({ session }) _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: loadLanguages
      , path: { session }
      , render: \langs ->
          uploadFileViewWithLangs (Record.merge props { langs })
      }

type PropsWithLangs =
  ( langs :: Array Lang
  | Props
  )

uploadFileViewWithLangs :: R2.Leaf PropsWithLangs
uploadFileViewWithLangs = R2.leaf uploadFileViewWithLangsCpt

uploadFileViewWithLangsCpt :: R.Component PropsWithLangs
uploadFileViewWithLangsCpt = here.component "uploadFileViewWithLangs" cpt
  where
  cpt { dispatch, langs, nodeType, session } _ = do
    -- mFile    :: R.State (Maybe UploadFile) <- R.useState' Nothing
    processed' /\ processed <- R2.useBox' false
    alertType' /\ alertType <- R2.useBox' "d-none" -- by default, the alert box is not displayed
    message' /\ message <- R2.useBox' ""
    mFile' /\ mFile <- R2.useBox' (Nothing :: Maybe UploadFile)
    fileType' /\ fileType <- R2.useBox' TSV
    fileFormat' /\ fileFormat <- R2.useBox' Plain
    lang <- T.useBox EN
    selection <- T.useBox ListSelection.MyListsFirst
    infoText' /\ infoText <- R2.useBox' ""

    let
      resetForm = do
        T.write_ false processed
        T.write_ "d-none" alertType
        T.write_ "" message
        T.write_ Nothing mFile
        T.write_ TSV fileType
        T.write_ Plain fileFormat
        T.write_ EN lang
        T.write_ ListSelection.MyListsFirst selection
        T.write_ infoText' infoText

    let setFileType' val = T.write_ val fileType >>= \_ -> T.write_ "You have manually selected the file type and/or format." infoText
    let setFileFormat' val = T.write_ val fileFormat >>= \_ -> T.write_ "You have manually selected the file type and/or format." infoText
    let setLang' val = T.write_ val lang

    let
      helpSection =
        [ H.details { className: "small p-1 pl-3 pb-2 alert-light" }
            [ H.summary {} [ H.text "Infos and allowed file types:" ]
            , H.p {}
                [ H.text "- TSV (Tab-separated values) or CSV (comma-separated values) - "
                , H.a { className: "text-primary", href: "https://write.frame.gargantext.org/s/c02ec18bb82b0333a3fdd93d8fc3b391b502742af51b2f8a8cb4de774fa07508", target: "_blank" }
                    [ H.text "Documentation with a TSV sample"
                    , B.icon { name: "external-link", className: "text-sup" }
                    ]
                , H.text ""
                , H.br {}
                , H.text "- TSV/CSV from HAL (specific format, sample soon available)"
                , H.br {}
                , H.text "- Istex (ZIP from the "
                , H.a { className: "text-primary", href: "https://dl.istex.fr", target: "_blank" }
                    [ H.text "dl.istex.fr"
                    , B.icon { name: "external-link", className: "text-sup" }
                    ]
                , H.text " search platform)"
                , H.br {}
                , H.text "- WOS: TXT file or a ZIP of TXT files from the Web Of Science platform - "
                , H.a { className: "text-primary", href: "https://write.frame.gargantext.org/s/1da0b1f7eeb4fcd75c32b19e71249231618102e09fed4c695dabd0bb13ada610", target: "_blank" }
                    [ H.text "Documentation about WOS"
                    , B.icon { name: "external-link", className: "text-sup" }
                    ]
                , H.text ""
                , H.br {}
                , H.text "- JSON or ZIP of JSON files (such as an exported GarganText corpus) - "
                , H.a { className: "text-primary", href: "https://write.frame.gargantext.org/s/83a6dee5e754072d4e239596d516f95aa807adc7b0e55622e875f259a3de8290", target: "_blank" }
                    [ H.text "Documentation about export"
                    , B.icon { name: "external-link", className: "text-sup" }
                    ]
                ]
            ]
        ]

    let
      fileName = case mFile' of
        Nothing -> "Browse / choose a file (TSV/CSV or JSON or TXT or Zip of files...)"
        Just file -> file.name

    let
      props =
        { dispatch
        , processed
        , message
        , fileFormat
        , fileType
        , lang
        , mFile
        , nodeType
        , selection
        , alertType
        , infoText
        }

    let
      fileUpload =
        [ R2.row
            [ H.div { className: "col-12 flex-space-around" }
                [ H.div { className: "form-group m-0" }
                    [ H.div { className: "custom-file" }
                        [ H.input
                            { type: "file"
                            , className: "form-control"
                            , placeholder: "Choose file"
                            , on: { change: onChangeContents props }
                            , id: "customImportFile"
                            }
                        , H.label { className: "custom-file-label", for: "customImportFile" }
                            [ H.text fileName
                            ]
                        ]
                    ]
                -- , H.text message'
                ]
            ]
        ]

    let
      alertMessage =
        [ H.div { className: "alert " <> alertType', role: "alert" }
            [ H.text message' ]
        ]

    let
      selects =
        if processed' then
          [ R2.row
              [ H.div { className: "col-12 form-group]" }
                  [ B.formSelect'
                      { list:
                          [ TSV
                          , TSV_HAL
                          , Istex
                          , WOS
                          , JSON
                          -- , Iramuteq
                          ]
                      , value: fileType'
                      , callback: setFileType'
                      }
                      []
                  , B.formSelect'
                      { list:
                          [ Plain
                          , ZIP
                          ]
                      , value: fileFormat'
                      , callback: setFileFormat'
                      }
                      []
                  ]
              ]
          , H.div { className: "alert alert-info", role: "alert" }
              [ H.text infoText' ]
          , R2.row
              [ H.div { className: "col-12" }
                  [ Tools.formChoiceSafe
                      { items: langs <> [ No_extraction ]
                      , default: EN
                      , callback: setLang'
                      , print: show
                      , label: "Please choose the language of your documents:"
                      }
                      []
                  ]
              ]
          , R2.row
              [ H.div { className: "col-12 form-group" }
                  [ H.label {} [ H.text "Please choose the list of terms to use:" ]
                  , H.details { className: "small p-1 pl-3 pb-2 alert-light" }
                      [ H.summary {} [ H.text "Infos about this choice:" ]
                      , H.p {}
                          [ H.text "- My list first: the analysis will generate a list of terms based on your previous lists of terms"
                          , H.br {}
                          , H.text "- NoList: the analysis will not generate any terms"
                          ]
                      ]
                  , ListSelection.selection { selection, session } []
                  ]
              ]
          ]
        else []

    -- let alertMessage2 = if processed' then
    --                     [
    --                     -- if infoText' /= "" then
    --                       H.div { className: "alert alert-info" , role: "alert" }
    --                       [ H.text infoText' ]
    --                     -- else mempty
    --                     ]
    --                     else []

    let bodies = helpSection <> fileUpload <> alertMessage <> selects
    -- let footer = H.div {} [ uploadButton props [] ]
    let
      footer = H.div { className: "d-flex justify-content-space-between" }
        [ H.button
            { className: "btn btn-light mx-2"
            , on:
                { click: \e -> do
                    resetForm
                    onChangeContents props e
                }
            }
            [ H.text "Reset" ]
        , uploadButton props []
        ]
    pure $ Tools.panel
      { mError: Nothing
      , iconName: "upload"
      , textTitle: "Upload and analyze a corpus"
      }
      (bodies <> [ footer ])

-- | Properties of a file upload
type UploadFileProps =
  ( dispatch :: Action -> Aff Unit
  , processed :: T.Box Boolean -- ^ file was uploaded and processed
  , message :: T.Box String -- ^ (error) message shown on file selection
  , fileFormat :: T.Box FileFormat -- ^ file format of the upload, e.g. Plain or ZIP
  , fileType :: T.Box FileType -- ^ file type of the upload, e.g. TSV, JSON, etc.
  , lang :: T.Box Lang -- ^ language of the uploaded document
  , mFile :: T.Box (Maybe UploadFile)
  , nodeType :: GT.NodeType
  , selection :: T.Box ListSelection.Selection
  , alertType :: T.Box String
  , infoText :: T.Box String
  )

-- | Callback called upon selecting a file in the upload file selector.
onChangeContents :: forall e. Record UploadFileProps -> E.SyntheticEvent_ e -> Effect Unit
onChangeContents
  props@
    { dispatch
    , processed
    , message
    , fileFormat
    , fileType
    , lang
    , mFile
    , nodeType
    , selection
    , alertType
    , infoText
    }
  e = do
  let mF = R2.inputFileNameWithBlob 0 e
  E.preventDefault e
  E.stopPropagation e
  case mF of
    Nothing -> pure unit
    Just { blob, name } -> do
      T.write_ (Just $ { blob: UploadFileBlob blob, name }) mFile
      checkFileUpdateParams props

uploadButton :: R2.Component UploadFileProps
uploadButton = R.createElement uploadButtonCpt

-- | String pattern used to parse extensions in file paths
fileExtensionPattern :: String
fileExtensionPattern = "(.*)\\.(.*)"

-- | Given a file name, it parses the extension and returns the lower case extension and
-- otherwise `Nothing`.
extensionFromFileName :: String -> Maybe String
extensionFromFileName name = do
  reg <- hush (DSR.regex fileExtensionPattern DSRF.noFlags)
  matches <- DSR.match reg name
  case DAN.toArray matches of
    [ _, _, ext ] -> DS.toLower <$> ext
    _ -> Nothing

-- | Given a file name, it parses the extension and checks if it is `.zip`. If yes,
-- it returns `Just true` otherwise `Just false`. If the parsing fails, it returns `Nothing`.
isZIPFromFileName :: String -> Maybe Boolean
isZIPFromFileName name = do
  ext <- extensionFromFileName name
  case ext of
    "zip" -> pure true
    _ -> pure false

-- | Given a file name, this returns one of the pre-defined file types based on the extension
-- of the filename and `Nothing` if the extension is not recognized.
fileTypeFromFileName :: String -> Maybe FileType
fileTypeFromFileName name = do
  ext <- extensionFromFileName name
  case ext of
    -- There should be more file extensions here, but I don't understand the other
    -- constructors of `FileType`
    "csv" -> pure TSV
    "tsv" -> pure TSV
    "json" -> pure JSON
    "txt" -> pure WOS
    "corpus" -> pure Istex
    _ -> Nothing

-- | Given an array of file names, extract file types from the extensions and return the
-- unique file type. If any of the steps fail, an error message is returned.
fileTypeFromFileNames :: Array String -> Either String FileType
fileTypeFromFileNames names = do
  fts <- note "Some extensions were not recognized." (traverse fileTypeFromFileName names)
  ft <- note "Found no files in the archive" (A.head fts)
  case Set.size (Set.fromFoldable fts) of
    0 -> Left "Found no files in the archive."
    1 -> Right ft
    2 ->
      if Set.member JSON (Set.fromFoldable fts) && Set.member Istex (Set.fromFoldable fts) then Right Istex
      else Left "More than one file type found in the archive."
    _ -> Left "More than one file type found in the archive."

-- | Check the selected file in the upload file selector, analyze the extension
-- and inform the user by updating visible parameters.
checkFileUpdateParams :: Record UploadFileProps -> Effect Unit
checkFileUpdateParams
  { dispatch
  , processed
  , message
  , fileFormat
  , fileType
  , lang
  , mFile
  , nodeType
  , selection
  , alertType
  , infoText
  } = do
  processed' <- T.read processed
  fileType' <- T.read fileType
  fileFormat' <- T.read fileFormat
  mFile' <- T.read mFile
  lang' <- T.read lang
  selection' <- T.read selection
  let { blob, name } = unsafePartial $ fromJust mFile'
  case isZIPFromFileName name of
    Just true -> do
      void $ launchAff do
        contents <- readUFBAsArrayBuffer blob
        files <- (ZIP.getFiles contents :: Aff (Array String))
        case fileTypeFromFileNames files of
          Right ft -> liftEffect $ do
            here.log2 "[uploadFileCheck] found filetype" ft
            T.write_ ft fileType
            T.write_ ZIP fileFormat
            -- update processed and message
            T.write_ true processed
            let tmp_msg = name <> " is allowed. Let's continue."
            T.write_ tmp_msg message
            T.write_ "alert-success" alertType
            T.write_ "The above settings were found automatically, based on the extension of the provided file. Please check and change if needed." infoText
          Left err -> liftEffect $ do
            here.log2 "[uploadFileCheck] error" err
            T.write_ false processed
            T.write_ err message
            T.write_ "alert-danger" alertType
    Just false -> do
      case fileTypeFromFileName name of
        Just ft -> do
          here.log2 "[uploadFileCheck] found filetype" ft
          -- write parsed `FileType` to the `fileType'` box
          T.write_ ft fileType
          T.write_ Plain fileFormat
          -- update processed and message
          T.write_ true processed
          let tmp_msg = name <> " is allowed. Let's continue."
          T.write_ tmp_msg message
          T.write_ "alert-success" alertType
          T.write_ "The above settings were found automatically, based on the extension of the provided file. Please check and change if needed." infoText
        Nothing -> do
          here.log2 "[uploadFileCheck] unkown filetype" name
          T.write_ false processed
          T.write_ "This filetype is not supported." message
          T.write_ "alert-danger" alertType
    Nothing -> do
      here.log2 "[uploadFileCheck] extension invalid" name
      T.write_ false processed
      T.write_ "The file extension is invalid." message
      T.write_ "alert-danger" alertType

uploadButtonCpt :: R.Component UploadFileProps
uploadButtonCpt = here.component "uploadButton" cpt
  where
  cpt
    { dispatch
    , processed
    , message
    , fileFormat
    , fileType
    , lang
    , mFile
    , nodeType
    , selection
    , alertType
    }
    _ = do
    processed' <- T.useLive T.unequal processed
    fileType' <- T.useLive T.unequal fileType
    fileFormat' <- T.useLive T.unequal fileFormat
    mFile' <- T.useLive T.unequal mFile
    lang' <- T.useLive T.unequal lang
    selection' <- T.useLive T.unequal selection
    onPending /\ onPendingBox <- R2.useBox' false

    let disabled = isNothing mFile' || onPending || not processed'

    pure $
      H.div
        { className: "action-upload-button" }
        [ if onPending then H.div
            { className: intercalate " "
                [ "spinner-grow"
                , "action-upload-button__spinner"
                ]
            }
            []
          else mempty
        , H.button
            { className: "btn btn-primary"
            , "type": "button"
            , disabled
            , style: { width: "100%" }
            , on:
                { click: onClick
                    fileFormat'
                    fileType'
                    mFile'
                    lang'
                    selection'
                    onPendingBox
                }
            }
            [ H.text "Upload" ]
        ]

    where
    onClick fileFormat' fileType' mFile' lang' selection' onPendingBox _e = do
      let { blob, name } = unsafePartial $ fromJust mFile'
      T.write_ true onPendingBox
      here.log2 "[uploadButton] fileType" fileType'
      here.log2 "[uploadButton] name" (fileTypeFromFileName name)
      void $ launchAff do
        case fileType' of
          Arbitrary ->
            dispatch $ UploadArbitraryFile fileFormat' (Just name) blob
          _ -> do
            contents <- case fileFormat' of
              Plain -> readUFBAsText blob
              ZIP -> readUFBAsBase64 blob
            dispatch $ UploadFile nodeType fileType' fileFormat' lang' (Just name) contents selection'
        liftEffect $ do
          T.write_ Nothing mFile
          T.write_ TSV fileType
          T.write_ Plain fileFormat
          T.write_ EN lang
          T.write_ false onPendingBox
        dispatch CloseBox

uploadListView :: R2.Leaf Props
uploadListView = R2.leaf uploadListViewCpt

uploadListViewCpt :: R.Component Props
uploadListViewCpt = here.component "uploadListView" cpt
  where
  cpt { dispatch, session } _ = do
    -- States
    processed <- T.useBox false
    message <- T.useBox ""
    mFile <- T.useBox (Nothing :: Maybe UploadFile)
    fileType <- T.useBox TSV
    fileFormat /\ fileFormatBox <- R2.useBox' Plain
    lang /\ langBox <- R2.useBox' EN
    selection <- T.useBox ListSelection.MyListsFirst
    alertType <- T.useBox ""
    infoText <- T.useBox ""
    let
      props =
        { dispatch
        , processed
        , message
        , fileFormat: fileFormatBox
        , fileType
        , lang: langBox
        , mFile
        , selection
        , alertType
        , infoText
        , nodeType: GT.Annuaire
        }

    -- Render
    pure $
      Tools.panel
        { mError: Nothing
        , iconName: "upload"
        , textTitle: "Upload (and replace) a list of terms"
        }
        -- Body
        [
          -- Upload
          H.div
            { className: "form-group" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "Upload file"
                ]
            , H.div
                { className: "form-group__field" }
                [ H.input
                    { type: "file"
                    , className: "form-control"
                    , placeholder: "Choose file"
                    , on: { change: onChangeContents props }
                    }
                ]
            ]
        ,
          -- Format
          H.div
            { className: "form-group" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "File format"
                ]
            , H.div
                { className: "form-group__field d-flex justify-content-between" }
                [ B.formSelect
                    { callback: \_ -> pure unit
                    , value: show TSV
                    , status: Disabled
                    , className: "col-5"
                    }
                    [ H.option
                        { value: show TSV }
                        [ H.text $ show TSV ]
                    ]
                , B.formSelect'
                    { callback: flip T.write_ fileFormatBox
                    , value: fileFormat
                    , list: [ Plain, ZIP ]
                    , className: "col-5"
                    }
                    []
                ]
            ]
        ,
          -- Lang
          H.div
            { className: "form-group" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "File lang"
                ]
            , H.div
                { className: "form-group__field" }
                [ B.formSelect'
                    { callback: flip T.write_ langBox
                    , value: lang
                    , list: [ EN, FR, No_extraction, Universal ]
                    }
                    []
                ]
            ]
        ,
          -- List selection
          H.div
            { className: "form-group" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "List selection"
                ]
            , H.div
                { className: "form-group__field" }
                [ ListSelection.selection
                    { selection
                    , session
                    }
                    []
                ]
            ]

        -- Footer
        , H.div
            {}
            [ uploadButton props []
            ]
        ]

-- START File Type View
type FileTypeProps =
  ( dispatch :: Action -> Aff Unit
  , droppedFile :: T.Box (Maybe DroppedFile)
  , id :: ID
  , isDragOver :: T.Box Boolean
  , nodeType :: GT.NodeType
  , key :: String
  )

fileTypeView :: R2.Leaf FileTypeProps
fileTypeView = R2.leaf fileTypeViewCpt

fileTypeViewCpt :: R.Component FileTypeProps
fileTypeViewCpt = here.component "fileTypeView" cpt
  where
  cpt
    { dispatch
    , droppedFile
    , isDragOver
    , nodeType
    }
    _ = do
    droppedFile' <- T.useLive T.unequal droppedFile

    pure $
      R2.fromMaybe droppedFile' \df ->
        H.div
          tooltipProps
          [ H.div { className: "card" }
              [ panelHeading
              , panelBody df
              , panelFooter df
              ]
          ]

    where
    tooltipProps =
      { className: ""
      , id: "file-type-tooltip"
      , title: "Choose file type"
      , data:
          { toggle: "tooltip"
          , placement: "right"
          }
      }
    panelHeading =
      H.div { className: "card-header" }
        [ H.div { className: "row" }
            [ H.div { className: "col-md-10" }
                [ H.h5 { className: "font-weight-normal" } [ H.text "Choose file type" ] ]
            , H.div { className: "col-md-2" }
                [ H.a
                    { className: "btn glyphitem fa fa-remove-circle"
                    , on:
                        { click: \_ -> do
                            T.write_ Nothing droppedFile
                            T.write_ false isDragOver
                        }
                    , title: "Close"
                    }
                    []
                ]
            ]
        ]

    panelBody (DroppedFile { blob }) =
      H.div { className: "card-body toolbox-tab-content px-5 py-4" }
        [ R2.select
            { className: "col-md-12 form-control"
            , on: { change: onChange }
            }
            (map renderOption [ TSV, TSV_HAL, WOS, Iramuteq ])
        ]
      where
      onChange e l =
        T.write_
          ( Just $ DroppedFile $
              { blob
              , fileType: read $ R.unsafeEventValue e
              , lang: fromMaybe EN $ langReader $ R.unsafeEventValue l
              }
          )
          droppedFile
      renderOption opt = H.option {} [ H.text $ show opt ]

    panelFooter (DroppedFile { blob, fileType, lang }) =
      H.div { className: "card-footer" }
        [ case fileType of
            Just ft ->
              H.button
                { className: "btn btn-success"
                , type: "button"
                , on:
                    { click: \_ -> do
                        T.write_ Nothing droppedFile
                        launchAff $ do
                          contents <- readUFBAsText blob
                          dispatch $ UploadFile nodeType ft Plain lang Nothing contents (SelectedLists [])
                    }
                }
                [ H.text "Upload" ]
            Nothing ->
              H.button
                { className: "btn btn-success disabled"
                , type: "button"
                }
                [ H.text "Upload" ]
        ]

newtype FileUploadQuery = FileUploadQuery
  { fileType :: FileType
  }

derive instance Newtype FileUploadQuery _
instance GT.ToQuery FileUploadQuery where
  toQuery (FileUploadQuery { fileType }) =
    QP.print id id $ QP.QueryPairs $
      pair "fileType" fileType
    where
    pair :: forall a. Show a => String -> a -> Array (Tuple QP.Key (Maybe QP.Value))
    pair k v = [ QP.keyFromString k /\ (Just $ QP.valueFromString $ show v) ]

uploadFile
  :: { contents :: String
     , fileFormat :: FileFormat
     , fileType :: FileType
     , id :: ID
     , lang :: Lang
     , nodeType :: GT.NodeType
     , mName :: Maybe String
     , selection :: ListSelection.Selection
     , session :: Session
     }
  -> AffRESTError GAT.Task
uploadFile { contents, fileFormat, lang, fileType, id, nodeType, mName, selection, session } = do
  -- contents <- readAsText blob
  postWwwUrlencoded session p body
  --postMultipartFormData session p fileContents
  where
  bodyParams =
    [ Tuple "_wf_data" (Just contents)
    , Tuple "_wf_filetype" (Just $ show fileType)
    , Tuple "_wf_fileformat" (Just $ show fileFormat)
    , Tuple "_wf_lang" (Just $ show lang)
    , Tuple "_wf_name" mName
    , Tuple "_wf_selection" (Just $ show selection)
    ]
  jsonBodyParams =
    [ Tuple "_wjf_data" (Just contents)
    , Tuple "_wjf_filetype" (Just $ show fileType)
    , Tuple "_wjf_name" mName
    ]
  tsvBodyParams =
    [ Tuple "_wtf_data" (Just contents)
    , Tuple "_wtf_filetype" (Just $ show fileType)
    , Tuple "_wtf_fileformat" (Just $ show fileFormat)
    , Tuple "_wf_lang" (Just $ show lang)
    , Tuple "_wtf_name" mName
    , Tuple "_wtf_selection" (Just $ show selection)
    ]

  (typ /\ p /\ body) = case nodeType of
    Corpus -> GT.CorpusFormUpload /\ (GR.NodeAPI nodeType (Just id) $ GT.asyncTaskTypePath GT.CorpusFormUpload) /\ bodyParams
    Annuaire -> GT.UploadFile /\ (GR.NodeAPI nodeType (Just id) "annuaire") /\ bodyParams
    NodeList -> case fileType of
      JSON -> GT.ListUpload /\ (GR.NodeAPI nodeType (Just id) $ GT.asyncTaskTypePath GT.ListUpload) /\ jsonBodyParams
      TSV -> GT.ListCSVUpload /\ (GR.NodeAPI NodeList (Just id) $ GT.asyncTaskTypePath GT.ListCSVUpload) /\ tsvBodyParams
      _ -> GT.UploadFile /\ (GR.NodeAPI nodeType (Just id) "") /\ bodyParams
    _ -> GT.UploadFile /\ (GR.NodeAPI nodeType (Just id) "") /\ bodyParams

uploadArbitraryFile
  :: Session
  -> ID
  -> { blob :: UploadFileBlob, fileFormat :: FileFormat, mName :: Maybe String }
  -> AffRESTError GAT.Task
uploadArbitraryFile session id { fileFormat, mName, blob: UploadFileBlob blob } = do
  contents <- readAsDataURL blob
  uploadArbitraryData session id fileFormat mName contents

uploadArbitraryData
  :: Session
  -> ID
  -> FileFormat
  -> Maybe String
  -> String
  -> AffRESTError GAT.Task
uploadArbitraryData session id fileFormat mName contents' = do
  let
    re = fromRight' (\_ -> unsafeCrashWith "Unexpected Left") $ DSR.regex "data:.*;base64," DSRF.noFlags
    contents = DSR.replace re "" contents'
  postWwwUrlencoded session p (bodyParams contents)
  where
  p = GR.NodeAPI GT.Node (Just id) $ GT.asyncTaskTypePath GT.UploadFile

  bodyParams c =
    [ Tuple "_wfi_b64_data" (Just c)
    , Tuple "_wfi_fileformat" (Just $ show fileFormat)
    , Tuple "_wfi_name" mName
    ]

------------------------------------------------------------------------

uploadTermListView :: R2.Component Props
uploadTermListView = R.createElement uploadTermListViewCpt

uploadTermListViewCpt :: R.Component Props
uploadTermListViewCpt = here.component "uploadTermListView" cpt
  where
  cpt { dispatch, nodeType } _ = do
    let defaultUploadType = JSON
    mFile <- T.useBox (Nothing :: Maybe UploadFile)
    uploadType <- T.useBox defaultUploadType

    let
      input = H.input
        { type: "file"
        , placeholder: "Choose file"
        , on: { change: onChangeContents mFile }
        , className: "form-control"
        }

    let opt fileType = H.option { value: show fileType } [ H.text $ show fileType ]

    let
      uploadTypeHtml = R2.select
        { className: "form-control"
        , defaultValue: show defaultUploadType
        , on: { change: onUploadTypeChange uploadType }
        }
        (opt <$> [ TSV, JSON ])

    let
      footer = H.div {}
        [ uploadTermButton
            { dispatch
            , mFile
            , nodeType
            , uploadType
            }
        ]

    pure $ Tools.panel
      { mError: Nothing
      , iconName: "upload"
      , textTitle: "Upload (and replace) a list of terms"
      }
      [ H.form {}
          [ R2.row [ R2.col 12 [ input ] ]
          , R2.row [ R2.col 12 [ uploadTypeHtml ] ]
          ]
      , footer
      ]

  onChangeContents
    :: forall e
     . T.Box (Maybe UploadFile)
    -> E.SyntheticEvent_ e
    -> Effect Unit
  onChangeContents mFile e = do
    let mF = R2.inputFileNameWithBlob 0 e
    E.preventDefault e
    E.stopPropagation e
    case mF of
      Nothing -> pure unit
      Just { blob, name } -> void $ launchAff do
        --contents <- readAsText blob
        liftEffect $ do
          T.write_
            ( Just $
                { blob: UploadFileBlob blob
                , name
                }
            )
            mFile

  onUploadTypeChange uploadType e = do
    case read (R.unsafeEventValue e) of
      Nothing -> pure unit
      Just fileType -> T.write_ fileType uploadType

type UploadTermButtonProps =
  ( dispatch :: Action -> Aff Unit
  , mFile :: T.Box (Maybe UploadFile)
  , nodeType :: GT.NodeType
  , uploadType :: T.Box FileType
  )

uploadTermButton :: R2.Leaf UploadTermButtonProps
uploadTermButton = R2.leaf uploadTermButtonCpt

uploadTermButtonCpt :: R.Component UploadTermButtonProps
uploadTermButtonCpt = here.component "uploadTermButton" cpt
  where
  cpt
    { dispatch
    , mFile
    , nodeType
    , uploadType
    }
    _ = do
    mFile' <- T.useLive T.unequal mFile
    uploadType' <- T.useLive T.unequal uploadType

    R.useEffect' $ do
      here.log2 "[uploadTermButton] uploadType'" uploadType'

    let
      disabled = case mFile' of
        Nothing -> "1"
        Just _ -> ""

    pure $ H.button
      { className: "btn btn-primary"
      , disabled
      , on: { click: onClick mFile' uploadType' }
      }
      [ H.text "Upload" ]
    where
    onClick mFile' uploadType' _ = do
      let { name, blob } = unsafePartial $ fromJust mFile'
      void $ launchAff do
        contents <- readUFBAsText blob
        _ <- dispatch $ UploadFile nodeType uploadType' Plain EN (Just name) contents (SelectedLists [])
        liftEffect $ do
          T.write_ Nothing mFile

------------------------------------------------------------------------

uploadFrameCalcView :: R2.Component Props
uploadFrameCalcView = R.createElement uploadFrameCalcViewCpt

uploadFrameCalcViewCpt :: R.Component Props
uploadFrameCalcViewCpt = R2.hereComponent here "uploadFrameCalcView" hCpt
  where
  hCpt hp props@({ session }) _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: loadLanguages
      , path: { session }
      , render: \langs ->
          uploadFileViewWithLangs (Record.merge props { langs })
      }

uploadFrameCalcViewWithLangs :: R2.Component PropsWithLangs
uploadFrameCalcViewWithLangs = R.createElement uploadFrameCalcViewWithLangsCpt

uploadFrameCalcViewWithLangsCpt :: R.Component PropsWithLangs
uploadFrameCalcViewWithLangsCpt = here.component "uploadFrameCalcViewWithLangs" cpt
  where
  cpt { dispatch, langs, session } _ = do
    lang' /\ langBox <- R2.useBox' $ fromMaybe EN $ A.head langs
    selection' /\ selectionBox <- R2.useBox' ListSelection.MyListsFirst

    let
      bodies =
        [ H.div
            { className: "col-12 flex-space-around" }
            [ H.h4 {}
                [ H.text "This will upload current calc as Corpus TSV" ]
            ]
        ,
          -- Lang
          H.div
            { className: "form-group" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "File lang"
                ]
            , H.div
                { className: "form-group__field" }
                [ B.formSelect'
                    { callback: flip T.write_ langBox
                    , value: lang'
                    , list: langs <> [ No_extraction, Universal ]
                    }
                    []
                ]
            ]
        ,
          -- List selection
          H.div
            { className: "form-group" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "List selection"
                ]
            , H.div
                { className: "form-group__field" }
                [ ListSelection.selection
                    { selection: selectionBox
                    , session
                    }
                    []
                ]
            ]
        ]

    let
      footer = H.div {}
        [ H.button
            { className: "btn btn-primary"
            , on: { click: onClick lang' selection' }
            }
            [ H.text "Upload!" ]
        ]

    pure $ Tools.panel
      { mError: Nothing
      , iconName: "upload"
      , textTitle: "Upload a calc"
      }
      (bodies <> [ footer ])

    where
    onClick lang' selection' _ = do
      void $ launchAff do
        dispatch $ UploadFrameCalc lang' selection'

uploadFrameCalc
  :: Session
  -> ID
  -> Lang
  -> ListSelection.Selection
  -> AffRESTError GAT.Task
uploadFrameCalc session id lang selection = do
  let p = GR.NodeAPI GT.Node (Just id) $ GT.asyncTaskTypePath GT.UploadFrameCalc

  post session p
    { _wf_lang: Just lang
    , _wf_selection: selection
    }
