module Gargantext.Components.Forest.Tree.Node.Action.Documentation where

import Data.Maybe (Maybe(..))
import Gargantext.Components.Bootstrap as B
import Gargantext.Components.Forest.Tree.Node.Tools as Tools
import Gargantext.Prelude (map, pure, show, ($), (<>))
import Gargantext.Types (NodeType)
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.Documentation"

-- | Action: Show Documentation
type ActionDoc =
  (nodeType :: NodeType)

actionDoc :: R2.Component ActionDoc
actionDoc = R.createElement actionDocCpt

actionDocCpt :: R.Component ActionDoc
actionDocCpt = here.component "actionDoc" cpt
  where
  cpt { nodeType } _ = do
    pure $
      Tools.panelNoFooter { mError: Nothing, iconName: "question-circle", textTitle: "Documentation" }

        ( [ H.div {}
              [ H.div { className: "" }
                  [ H.dl { className: "row" }
                      [
                        --  node type name
                        H.dt { className: "text-bold col-3" } [ H.text "Node type name" ]
                      , H.dd { className: "col-9" }
                          [ H.span { className: GT.fldr nodeType true } []
                          , H.span { className: "mx-1" } [ H.text $ show nodeType ]
                          ]
                      --  node description
                      , H.dt { className: "text-bold col-3" } [ H.text "Description" ]
                      , H.dd { className: "col-9" }
                          [ R.fragment (map (\info -> H.p {} [ H.text info ]) $ docOf nodeType) ]
                      ]
                  ]
              ]
          --  <> (map (\info -> H.p {} [ H.text info ]) $ docOf nodeType)
          ]
        )

-- | TODO add documentation of all NodeType
docOf :: NodeType -> Array String
docOf GT.NodeUser =
  [ "This account is personal"
  , "See the instances terms of uses."
  ]
docOf GT.FolderPrivate = [ "This folder and its children are private only." ]
docOf GT.FolderPublic = [ "Soon, you will be able to build public folders to share your work with the world!" ]
docOf GT.FolderShared = [ "Soon, you will be able to build teams folders to share your work" ]
docOf nodeType = [ "More information on " <> show nodeType ]

