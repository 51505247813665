// Based on https://github.com/jacomyal/sigma.js/blob/main/packages/sigma/src/rendering/programs/node-point/index.ts

import { floatColor } from "sigma/utils";
import { NodeProgram } from 'sigma/rendering';

import VERTEX_SHADER_SOURCE from "./circle-vert.glsl";
import FRAGMENT_SHADER_SOURCE from "./circle-frag.glsl";

const { UNSIGNED_BYTE, FLOAT } = WebGLRenderingContext;

const UNIFORMS = ["u_sizeRatio", "u_pixelRatio", "u_matrix"]

/*
export default class NodePointProgram<
  N extends Attributes = Attributes,
  E extends Attributes = Attributes,
  G extends Attributes = Attributes,
> extends NodeProgram<(typeof UNIFORMS)[number], N, E, G> {
*/

export default class NodeContourFastProgram extends NodeProgram {
  getDefinition() {
    return {
      VERTICES: 2,
      VERTEX_SHADER_SOURCE,
      FRAGMENT_SHADER_SOURCE,
      METHOD: WebGLRenderingContext.POINTS,
      UNIFORMS,
      ATTRIBUTES: [
        { name: "a_position", size: 2, type: FLOAT },
        { name: "a_size", size: 1, type: FLOAT },
        { name: "a_color", size: 4, type: UNSIGNED_BYTE, normalized: true },
        { name: "a_id", size: 4, type: UNSIGNED_BYTE, normalized: true },
      ],
    };
  }

  // processVisibleItem(nodeIndex: number, startIndex: number, data: NodeDisplayData) {
  processVisibleItem(nodeIndex, startIndex, data) {
    const array = this.array;
    const color = floatColor(data.color);
    //const black = floatColor('black');
    const gray = floatColor('#aaa')

    // contour
    array[startIndex++] = data.x;
    array[startIndex++] = data.y;
    array[startIndex++] = data.size + 1;
    //array[i++] = black;
    array[startIndex++] = gray;
    array[startIndex++] = nodeIndex;

    // circle
    array[startIndex++] = data.x;
    array[startIndex++] = data.y;
    array[startIndex++] = data.size;
    array[startIndex++] = color;
    array[startIndex++] = nodeIndex;
}

  //setUniforms({ sizeRatio, pixelRatio, matrix }: RenderParams, { gl, uniformLocations }: ProgramInfo): void {
  setUniforms({ sizeRatio, pixelRatio, matrix }, { gl, uniformLocations }) {
    const { u_sizeRatio, u_pixelRatio, u_matrix } = uniformLocations;

    gl.uniform1f(u_pixelRatio, pixelRatio);
    gl.uniform1f(u_sizeRatio, sizeRatio);
    gl.uniformMatrix3fv(u_matrix, false, matrix);
  }
}
