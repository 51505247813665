module Gargantext.Context.Progress
  ( AsyncProps
  , asyncProgress
  , asyncContext
  ) where

import Data.Either (Either(..))
import Data.Foldable (foldl)
import Data.Maybe (Maybe(..))
import Data.Tuple.Nested ((/\))
import Effect (Effect)
import Effect.Aff (Aff, launchAff_)
import Effect.Class (liftEffect)
import Effect.Timer (IntervalId, clearInterval, setInterval)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.App.Store as AppStore
import Gargantext.Components.Notifications as Notifications
import Gargantext.Components.Notifications.Types as NT
import Gargantext.Config.Utils (handleErrorInAsyncTaskLog, handleRESTError)
import Gargantext.Hooks.FirstEffect (useFirstEffect')
import Gargantext.Prelude
import Gargantext.Sessions (Session)
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Record.Extra as RX
import Toestand as T

type AsyncProps =
  ( asyncTask :: GAT.Task
  , nodeId :: GT.ID
  , onFinish :: Unit -> Effect Unit
  , session :: Session
  )

here :: R2.Here
here = R2.here "Gargantext.Context.Progress"

-- | Temporary constant, shouldn't be here. It's used together with
-- | the 'resetInterval' function and when we fix the tasks so that
-- | they can inform about their finish/error, this can be removed
-- | along with 'resetInterval'.
defaultJobPollInterval :: Int
defaultJobPollInterval = 5000

asyncProgress :: R2.Component AsyncProps
asyncProgress = R2.component asyncProgressCpt

asyncProgressCpt :: R.Component AsyncProps
asyncProgressCpt = R2.hereComponent here "asyncProgress" hCpt
  where
  hCpt hp props@{ onFinish } children = do
    { errors, tasks, wsNotification } <- AppStore.use

    -- States
    progress /\ progressBox <- R2.useBox' 0.0
    intervalIdRef <- R.useRef (Nothing :: Maybe IntervalId)
    -- exponential backoff is used when errors are reported
    interval <- T.useBox 1000

    -- Methods

    let
      onProgress :: GT.AsyncTaskLog -> Aff Unit
      onProgress atl@(GT.AsyncTaskLog log) = liftEffect $ do

        T.write_ (min 100.0 $ GT.asyncTaskLogPercent atl) progressBox

        here.log "[onProgress] TODO: Implement status killed"
        if
          GT.asyncTaskLogIsFinished atl then do
          handleErrorInAsyncTaskLog errors atl
          -- resetInterval intervalIdRef Nothing (pure unit)
          onFinish unit
        else
          R.nothing

    -- if (status == GT.IsFinished) ||
    --    (status == GT.IsKilled) ||
    --    (status == GT.IsFailure)
    -- then do
    --   -- resetInterval intervalIdRef Nothing exec
    --   -- case R.readRef intervalIdRef of
    --   --   Nothing  -> R.nothing
    --   --   Just iid -> clearInterval iid
    --   handleErrorInAsyncProgress errors value
    --   resetInterval intervalIdRef Nothing (pure unit)
    --   onFinish unit
    -- else
    --   R.nothing

    useFirstEffect' $ do
      let (GT.WorkerTask { message_id }) = props.asyncTask
      let
        cb n = do
          -- here.log2 "callback! for job update" taskId
          case n of
            NT.NUpdateWorkerProgress _wt jobProgress -> launchAff_ $ onProgress jobProgress
            _ -> pure unit

      -- resetInterval intervalIdRef (Just defaultJobPollInterval) fetchJobProgress

      -- The modal window has some problems closing when we refresh too early. This is a HACK
      -- void $ setTimeout 400 $ T2.reload reload
      ws <- T.read wsNotification
      let action = NT.InsertCallback (NT.UpdateWorkerProgress props.asyncTask) ("task-" <> show message_id) cb
      Notifications.performAction ws action

    -- Render
    pure $
      R.provideContext asyncContext (progress)
        children

resetInterval :: R.Ref (Maybe IntervalId) -> Maybe Int -> Effect Unit -> Effect Unit
resetInterval ref mInt exec = do
  case R.readRef ref /\ mInt of
    Nothing /\ Nothing ->
      pure unit
    Nothing /\ Just interval' -> do
      intervalId <- setInterval interval' exec'
      R.setRef ref $ Just intervalId
    Just iid /\ Nothing -> do
      clearInterval iid
      R.setRef ref Nothing
    Just iid /\ Just interval' -> do
      clearInterval iid
      intervalId <- setInterval interval' exec'
      R.setRef ref $ Just intervalId
  where
  exec' = do
    here.log "[resetInterval] calling"
    exec

asyncContext :: R.Context (Number)
asyncContext = R.createContext 0.0
