module Gargantext.Components.Forest.Tree.Node.Action.WriteNodesDocuments where

import Data.Either (Either)
import Data.Maybe (Maybe(..))
import Data.Tuple.Nested ((/\))
import Effect.Aff (Aff)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.Bootstrap as B
import Gargantext.Components.Forest.Tree.Node.Action.Types (Action(..))
import Gargantext.Components.Forest.Tree.Node.Action.Utils (loadLanguages)
import Gargantext.Components.Forest.Tree.Node.Tools as Tools
import Gargantext.Components.Lang (Lang(..))
import Gargantext.Components.ListSelection as ListSelection
import Gargantext.Components.ListSelection.Types as ListSelection
import Gargantext.Config.REST (AffRESTError, RESTError)
import Gargantext.Hooks.Loader (useLoader)
import Gargantext.Prelude
import Gargantext.Routes (SessionRoute(..))
import Gargantext.Sessions (Session, post)
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Record as Record
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.WriteNodesDocuments"

-- | Action : WriteNodesDocuments
type ActionWriteNodesDocuments =
  ( dispatch :: Action -> Aff Unit
  , id :: GT.ID
  , session :: Session
  )

actionWriteNodesDocuments :: R2.Component ActionWriteNodesDocuments
actionWriteNodesDocuments = R.createElement actionWriteNodesDocumentsCpt

actionWriteNodesDocumentsCpt :: R.Component ActionWriteNodesDocuments
actionWriteNodesDocumentsCpt = R2.hereComponent here "actionWriteNodesDocuments" hCpt
  where
  hCpt hp props@({ session }) _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: loadLanguages
      , path: { session }
      , render: \langs ->
          actionWriteNodesDocumentsWithLangs (Record.merge props { langs }) []
      }

type ActionWriteNodesDocumentsWithLangs =
  ( langs :: Array Lang
  | ActionWriteNodesDocuments
  )

actionWriteNodesDocumentsWithLangs :: R2.Component ActionWriteNodesDocumentsWithLangs
actionWriteNodesDocumentsWithLangs = R.createElement actionWriteNodesDocumentsWithLangsCpt

actionWriteNodesDocumentsWithLangsCpt :: R.Component ActionWriteNodesDocumentsWithLangs
actionWriteNodesDocumentsWithLangsCpt = here.component "actionWriteNodesDocumentsWithLangs" cpt
  where
  cpt { dispatch, id, langs, session } _ = do

    -- lang' /\ langBox
    --     <- R2.useBox' $ fromMaybe EN $ A.head langs
    lang' /\ langBox <- R2.useBox' EN
    selection' /\ selectionBox <- R2.useBox' ListSelection.MyListsFirst
    paragraphs' /\ paragraphBox <- R2.useBox' "7"

    pure $
      Tools.panelWithSubmitButton
        { action: DocumentsFromWriteNodes
            { id
            , lang: lang'
            , selection: selection'
            , paragraphs: paragraphs'
            }
        , dispatch
        , mError: Nothing
        , iconName: "file-text-o"
        , textTitle: "Import a corpus from your Notes"
        }
        [ H.p
            { className: "form-group" }
            [ H.text "Will traverse all the notes positioned under this NodeCorpus and inserts them as documents, making up your corpus." ]
        ,
          -- lang
          H.div
            { className: "form-group" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "Please select the language of your current Notes:"
                ]
            , H.div
                { className: "form-group__field" }
                [ B.formSelect'
                    { callback: flip T.write_ langBox
                    , value: lang'
                    , list: langs <> [ No_extraction ]
                    }
                    []
                ]
            ]
        ,
          -- paragraph
          H.div
            { className: "form-group " }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "Please set the document size you require:"
                ]
            , H.div
                { className: "form-group__field" }
                [ B.formInput
                    { callback: flip T.write_ paragraphBox
                    , value: paragraphs'
                    }
                , H.div { className: "small alert-light" }
                    [ H.text "Suggested: between 7 and 9 sentences per document" ]
                ]
            ]
        ,
          --selection
          H.div
            { className: "form-group m-0" }
            [ H.div
                { className: "form-group__label" }
                [ B.label_ $
                    "Please choose the list of terms to use:"
                ]
            , H.div
                { className: "form-group__field" }
                [ ListSelection.selection
                    { selection: selectionBox
                    , session
                    }
                    []
                ]
            ]
        ]

type Params =
  ( id :: GT.ID
  , lang :: Lang
  , paragraphs :: String
  , selection :: ListSelection.Selection
  )

documentsFromWriteNodesReq :: Session -> Record Params -> AffRESTError GAT.Task
documentsFromWriteNodesReq session params@{ id } = do
  post session (NodeAPI GT.Node (Just id) "documents-from-write-nodes") params
