module Gargantext.Components.Forest.Tree.Node.Action.Update where

import Gargantext.Prelude

import DOM.Simple.Console (log3)
import Data.Either (Either(..))
import Data.Maybe (Maybe(..))
import Effect (Effect)
import Effect.Aff (Aff, launchAff_)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.Bootstrap as B
import Gargantext.Components.Bootstrap.Types (ComponentStatus(..))
import Gargantext.Components.Forest.Tree.Node.Action.Types (Action(..))
import Gargantext.Components.Forest.Tree.Node.Action.Update.Types (BridgenessMethod(..), Charts(..), Granularity(..), GraphMetric(..), Method(..), Strength(..), UpdateNodeConfigGraph(..), UpdateNodeParams(..))
import Gargantext.Components.Forest.Tree.Node.Tools as Tools
import Gargantext.Components.PhyloExplorer.API as Phylo
import Gargantext.Components.PhyloExplorer.Config.ConfigForm as PhyloForm
import Gargantext.Components.PhyloExplorer.ConfigFormParser as PhyloHook
import Gargantext.Config.REST (AffRESTError)
import Gargantext.Routes as GR
import Gargantext.Sessions (Session, post)
import Gargantext.Types (ID, NodeType(..))
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Record (merge)
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.Update"

updateRequest :: UpdateNodeParams -> Session -> ID -> AffRESTError GAT.Task
updateRequest updateNodeParams session nodeId = do
  post session p updateNodeParams
  where
  p = GR.NodeAPI GT.Node (Just nodeId) "update"

----------------------------------------------------------------------
type UpdateProps =
  ( dispatch :: Action -> Aff Unit
  , nodeType :: NodeType
  )

update :: R2.Component UpdateProps
update = R.createElement updateCpt

updateCpt :: R.Component UpdateProps
updateCpt = here.component "update" cpt
  where
  cpt props@{ nodeType: Dashboard } _ = pure $ updateDashboard props []
  cpt props@{ nodeType: Graph } _ = pure $ updateGraph props []
  cpt props@{ nodeType: NodeList } _ = pure $ updateNodeList props []
  cpt props@{ nodeType: NodeTexts } _ = pure $ updateTexts props []
  cpt props@{ nodeType: Phylo } _ = pure $ updatePhylo props
  cpt props@{ nodeType: Corpus } _ = pure $ updateCorpus props
  cpt props@{ nodeType: _ } _ = pure $ updateOther props []

updateDashboard :: R2.Component UpdateProps
updateDashboard = R.createElement updateDashboardCpt

updateDashboardCpt :: R.Component UpdateProps
updateDashboardCpt = here.component "updateDashboard" cpt
  where
  cpt { dispatch } _ = do
    methodBoard <- T.useBox All
    methodBoard' <- T.useLive T.unequal methodBoard

    pure $
      Tools.panelWithSubmitButton
        { action: UpdateNode $ UpdateNodeParamsBoard { methodBoard: methodBoard' }
        , dispatch
        , mError: Nothing
        , iconName: ""
        , textTitle: "Update the dashboard"
        }
        [ Tools.formChoiceSafe
            { items: [ All, Sources, Authors, Institutes, Ngrams ]
            , default: methodBoard'
            , callback: \val -> T.write_ val methodBoard
            , print: show
            , label: "Update with:"
            }
            []
        ]

updateGraph :: R2.Component UpdateProps
updateGraph = R.createElement updateGraphCpt

updateGraphCpt :: R.Component UpdateProps
updateGraphCpt = here.component "updateGraph" cpt
  where
  cpt { dispatch } _ = do
    methodGraphMetric <- T.useBox Order1
    methodGraphMetric' <- T.useLive T.unequal methodGraphMetric

    methodGraphEdgesStrength <- T.useBox Strong
    methodGraphEdgesStrength' <- T.useLive T.unequal methodGraphEdgesStrength

    methodGraphNodeType1 <- T.useBox GT.CTabTerms
    methodGraphNodeType1' <- T.useLive T.unequal methodGraphNodeType1

    methodGraphNodeType2 <- T.useBox GT.CTabTerms
    methodGraphNodeType2' <- T.useLive T.unequal methodGraphNodeType2

    methodGraphBridgeness <- T.useBox BridgenessBasic
    methodGraphBridgeness' <- T.useLive T.unequal methodGraphBridgeness

    let
      callback :: Action -> Aff Unit
      callback = dispatch >=> \_ -> dispatch CloseBox

    let
      config = UpdateNodeConfigGraph
        { methodGraphMetric: methodGraphMetric'
        , methodGraphBridgeness: methodGraphBridgeness'
        , methodGraphEdgesStrength: methodGraphEdgesStrength'
        , methodGraphNodeType1: methodGraphNodeType1'
        , methodGraphNodeType2: methodGraphNodeType2'
        }
    let action = UpdateNode $ UpdateNodeParamsGraph { methodGraph: config }

    pure $
      Tools.panelWithSubmitButton
        { action
        , dispatch: callback
        , mError: Nothing
        , iconName: "reload-with-settings"
        , textTitle: "Update the graph"
        }
        [ Tools.formChoiceSafe
            { items: [ Order1, Order2 ]
            , default: methodGraphMetric'
            , callback: \val -> T.write_ val methodGraphMetric
            , print: show
            , label: "Show subjects with Order1 or concepts with Order2 ?"
            }
            []
        , Tools.formChoiceSafe
            { items: [ BridgenessBasic, BridgenessAdvanced ]
            , default: methodGraphBridgeness'
            , callback: \val -> T.write_ val methodGraphBridgeness
            , print: show
            , label: "Intercluster link filtering method"
            }
            []
        ]

updatePhylo :: R2.Leaf UpdateProps
updatePhylo = R2.leaf updatePhyloCpt

updatePhyloCpt :: R.Component UpdateProps
updatePhyloCpt = here.component "updatePhylo" cpt
  where
  cpt { dispatch } _ = do
    -- Hooks
    parser <- PhyloHook.useConfigFormParser

    -- Helpers
    let
      -- @NOTE #219: use a config property returned by GET phylo route
      defaultData :: Phylo.UpdateData
      defaultData = Phylo.UpdateData
        { defaultMode: true
        , proximity: 0.5
        , synchrony: 0.5
        , quality: 0.8
        , exportFilter: 3.0
        , timeUnit: Phylo.Year $ Phylo.TimeUnitCriteria
            { period: 3
            , step: 1
            , matchingFrame: 5
            }
        , clique: Phylo.MaxClique
            { size: 5
            , threshold: 0.0001
            , filter: Phylo.ByThreshold
            }
        }

      -- Behaviors

      onSubmit :: Record PhyloForm.FormData -> Effect Unit
      onSubmit r = case parser.fromFormData r of
        Left error -> log3 "[handleFormError]" error r
        Right r' -> do
          opts <- pure $ options r'
          launchAff_ do
            dispatch opts
            dispatch CloseBox

        where
        options :: Phylo.UpdateData -> Action
        options params = UpdateNode $ UpdateNodeParamsPhylo
          { methodPhylo: params
          }

    -- Render
    pure
      $ PhyloForm.configForm
      $
        { callback: onSubmit
        , status: Enabled
        } `merge` parser.toFormData defaultData

updateCorpus :: R2.Leaf UpdateProps
updateCorpus = R2.leaf updateCorpusCpt

updateCorpusCpt :: R.Component UpdateProps
updateCorpusCpt = here.component "updateTexts" cpt
  where
  cpt { dispatch } _ = do
    -- nodeList parameters
    methodList <- T.useBox Basic
    methodList' <- T.useLive T.unequal methodList

    let methodTexts = Both

    pure $
      Tools.panelWithSubmitButton
        { action: UpdateNode $ UpdateNodeParamsCorpus
            { methodTexts: methodTexts
            , methodList: methodList'
            }
        , dispatch
        , mError: Nothing
        , iconName: "reload-with-settings"
        , textTitle: "Update the corpus"
        }
        [ H.h5 { className: "text-primary font-weight-normal" }
            [ B.icon { name: "reload-with-settings" }
            , H.span { className: "px-1" } [ H.text "Update/resync both terms and document indexing" ]
            ]
        -- H.p {} [ H.text "Update both term and document indexing." ]
        , H.p {}
            [ Tools.formChoiceSafe
                { items: [ Basic, Advanced, WithModel ]
                , default: methodList'
                , callback: \val -> T.write_ val methodList
                , print: show
                , label: "Term update mode"
                }
                []
            ]
        , H.text "If you also want to update a graph or a phylo, use the update action on the respective node."
        ]

updateNodeList :: R2.Component UpdateProps
updateNodeList = R.createElement updateNodeListCpt

updateNodeListCpt :: R.Component UpdateProps
updateNodeListCpt = here.component "updateNodeList" cpt
  where
  cpt { dispatch } _ = do
    methodList <- T.useBox Basic
    methodList' <- T.useLive T.unequal methodList

    pure $
      Tools.panelWithSubmitButton
        { action: UpdateNode $ UpdateNodeParamsList { methodList: methodList' }
        , dispatch
        , mError: Nothing
        , iconName: "reload-with-settings"
        , textTitle: "Update terms"
        }
        [ Tools.formChoiceSafe
            { items: [ Basic, Advanced, WithModel ]
            , default: methodList'
            , callback: \val -> T.write_ val methodList
            , print: show
            , label: "Update with:"
            }
            []
        ]

updateTexts :: R2.Component UpdateProps
updateTexts = R.createElement updateTextsCpt

updateTextsCpt :: R.Component UpdateProps
updateTextsCpt = here.component "updateTexts" cpt
  where
  cpt { dispatch } _ = do
    -- methodTexts <- T.useBox NewNgrams
    -- methodTexts' <- T.useLive T.unequal methodTexts

    pure $
      Tools.panelWithSubmitButton
        { action: UpdateNode $ UpdateNodeParamsTexts { methodTexts: Both }
        , dispatch
        , mError: Nothing
        , iconName: "reload-with-settings"
        , textTitle: "Update documents (contexts)"
        }
        []

updateOther :: R2.Component UpdateProps
updateOther = R.createElement updateOtherCpt

updateOtherCpt :: R.Component UpdateProps
updateOtherCpt = here.component "updateOther" cpt
  where
  cpt _ _ = do
    pure $ H.div {} []
