/* global exports */
"use strict";

// module WebSocket

async function getFilesAsync_ (blob) {
  var JSZip = require("jszip");
  var zip = new JSZip();
  var data = await zip.loadAsync(blob);
  var vals = Object.values(data.files);
  var fns = [];
  for (var i = 0; i < vals.length; i++) {
    fns.push(vals[i].name);
    console.log(vals[i].name);
  }
  console.log(fns);
  return fns;
}

export const getFilesAsync = blob => () =>
  getFilesAsync_(blob);
