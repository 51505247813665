module Gargantext.Components.Forest.Tree.Node.Action.Search.Types where

import Gargantext.Prelude

import Data.Array as A
-- import Data.Bounded (class Bounded)
import Data.Either (Either)
-- import Data.Enum (class Enum, class BoundedEnum)
import Data.Generic.Rep (class Generic)
import Data.Maybe (Maybe(..), fromMaybe, maybe)
import Data.Newtype (class Newtype)
import Data.Set (Set)
import Data.Set as Set
import Data.Tuple (Tuple)
import Data.Tuple.Nested ((/\))
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.GraphQL.IMT as GQLIMT
import Gargantext.Components.Lang (Lang)
import Gargantext.Components.ListSelection.Types as ListSelection
import Gargantext.Config.REST (AffRESTError, RESTError)
import Gargantext.Ends (class ToUrl, backendUrl)
import Gargantext.Routes as GR
import Gargantext.Sessions (Session(..), post)
import Gargantext.Types as GT
import Simple.JSON as JSON
import URI.Extra.QueryPairs as QP
import URI.Query as Q

type Search =
  { datafield :: DataField
  , url :: String
  , lang :: Maybe Lang
  , node_id :: Maybe Int
  , term :: String
  , years :: Array String
  }

isIsTex_Advanced :: DataField -> Boolean
isIsTex_Advanced (External (IsTex_Advanced)) = true
isIsTex_Advanced _ = false

------------------------------------------------------------------------
class Doc a where
  doc :: a -> String

------------------------------------------------------------------------
-- | DataField search specifications

dataFields :: Array DataField
dataFields =
  [ {- Gargantext
  , -} External Empty
  , Web
  -- , Files
  ]

data DataField
  = Gargantext
  | External Database
  | Web
  | Files

derive instance Generic DataField _
instance Show DataField where
  show Gargantext = "Gargantext (Beta)"
  show (External _) = "Databases (APIs)" -- <> show x
  show Web = "Web"
  show Files = "Files"

instance Doc DataField where
  doc Gargantext = "All Gargantext Database"
  doc (External _) = "External (scientific) databases"
  doc Web = "To launch an analysis on french news (FR only supported for now): put your query, select FR and launch with button on bottom."
  doc Files = "Zip files with formats.."

derive instance Eq DataField
instance JSON.WriteForeign DataField where
  writeImpl (External db) = JSON.writeImpl { "External": db }
  writeImpl Gargantext = JSON.writeImpl "Gargantext"
  writeImpl Web = JSON.writeImpl "Web"
  writeImpl Files = JSON.writeImpl "Files"

----------------------------------------
data DataOriginApi
  = InternalOrigin { api :: Database }
  | ExternalOrigin { api :: Database }

derive instance Generic DataOriginApi _
instance Show DataOriginApi where
  show (InternalOrigin io) = "InternalOrigin " <> show io.api
  show (ExternalOrigin io) = "ExternalOrigin " <> show io.api

derive instance Eq DataOriginApi
instance JSON.WriteForeign DataOriginApi where
  writeImpl (InternalOrigin { api }) = JSON.writeImpl { api }
  writeImpl (ExternalOrigin { api }) = JSON.writeImpl { api }

datafield2dataOriginApi :: DataField -> DataOriginApi
datafield2dataOriginApi (External a) = ExternalOrigin { api: a }
datafield2dataOriginApi _ = InternalOrigin { api: IsTex } -- TODO fixme

------------------------------------------------------------------------
-- | Database search specifications

datafield2database :: DataField -> Database
datafield2database (External x) = x
datafield2database _ = Empty

data Database
  = All_Databases
  | Empty
  | OpenAlex
  | PubMed (Maybe String)
  | Arxiv
  | HAL (Maybe Org)
  | IsTex
  | IsTex_Advanced
  | Isidore
  | EPO (Maybe String) (Maybe String)

--              | News
--              | SocialNetworks
derive instance Generic Database _
-- derive instance Enum Database
-- derive instance Bounded Database
-- derive instance BoundedEnum Database
instance Show Database where
  show All_Databases = "All Databases"
  show OpenAlex = "OpenAlex"
  show (PubMed _) = "PubMed"
  show Arxiv = "Arxiv"
  show (HAL _) = "HAL"
  show IsTex = "IsTex"
  show IsTex_Advanced = "IsTex_Advanced"
  show Isidore = "Isidore"
  show (EPO _ _) = "EPO"
  show Empty = "Empty"

--  show News   = "News"
--  show SocialNetworks = "Social Networks"

instance Doc Database where
  doc All_Databases = "All databases"
  doc OpenAlex = "OpenAlex db"
  doc (PubMed _) = "All Medical publications"
  doc Arxiv = "Arxiv"
  doc (HAL _) = "All open science (archives ouvertes)"
  doc IsTex = "All Elsevier enriched by CNRS/INIST"
  doc IsTex_Advanced = "IsTex advanced search"
  doc Isidore = "All (French) Social Sciences"
  doc (EPO _ _) = "European Patent Office"
  doc Empty = "Empty"

--  doc News        = "Web filtered by News"
--  doc SocialNetworks = "Web filtered by MicroBlogs"

-- instance Read Database where
--   read :: String -> Maybe Database
--   read "All Databases"  = Just All_Databases
--   read "PubMed"         = Just PubMed
--   read "Arxiv"          = Just Arxiv
--   read "HAL"            = Just $ HAL Nothing
--   read "Isidore"        = Just Isidore
--   read "IsTex"          = Just IsTex
--   read "IsTex_Advanced" = Just IsTex_Advanced
--   -- read "Web"    = Just Web
--   -- read "News"   = Just News
--   -- read "Social Networks" = Just SocialNetworks
--   read _        = Nothing

derive instance Eq Database
instance JSON.WriteForeign Database where
  writeImpl (PubMed mAPIKey) = JSON.writeImpl { "db": "PubMed", "api_key": mAPIKey }
  writeImpl (EPO mAPIUser mAPIToken) = JSON.writeImpl
    { "db": "PubMed"
    , "api_user": mAPIUser
    , "api_token": mAPIToken
    }
  writeImpl (HAL mOrg) = JSON.writeImpl { "db": "HAL", "org": mOrg }
  writeImpl d = JSON.writeImpl { "db": show d }

allDatabases :: Array Database
allDatabases =
  [ Empty
  , PubMed Nothing
  , HAL Nothing
  , Arxiv
  , OpenAlex
  , IsTex
  -- , EPO Nothing Nothing
  --, IsTex_Advanced
  --, Isidore
  --, Web
  --, News
  --, SocialNetworks
  ]

dbToInputValue :: Database -> String
dbToInputValue All_Databases = "all_databases"
dbToInputValue OpenAlex = "openalex"
dbToInputValue (PubMed _) = "pubmed"
dbToInputValue Arxiv = "arxiv"
dbToInputValue (HAL _) = "hal"
dbToInputValue IsTex = "istex"
dbToInputValue IsTex_Advanced = "istex_advanced"
dbToInputValue Isidore = "isidore"
dbToInputValue (EPO _ _) = "epo"
dbToInputValue Empty = "empty"

dbFromInputValue :: String -> Maybe Database
dbFromInputValue "all_databases" = Just All_Databases
dbFromInputValue "openalex" = Just OpenAlex
dbFromInputValue "pubmed" = Just (PubMed Nothing)
dbFromInputValue "arxiv" = Just Arxiv
dbFromInputValue "hal" = Just (HAL Nothing)
dbFromInputValue "istex" = Just IsTex
dbFromInputValue "istex_advanced" = Just IsTex_Advanced
dbFromInputValue "isidore" = Just Isidore
dbFromInputValue "epo" = Just (EPO Nothing Nothing)
dbFromInputValue "empty" = Just Empty
dbFromInputValue _ = Nothing

------------------------------------------------------------------------
-- | Organization specifications

allOrgs :: Array Org
allOrgs =
  [ All_Orgs
  , IMT $ Set.fromFoldable []
  , CNRS $ Set.fromFoldable []
  ]

data Org
  = All_Orgs
  | CNRS (Set StructId)
  | Others (Set StructId)
  | IMT (Set IMT_org)

type StructId = Int

derive instance Generic Org _
instance Show Org where
  show All_Orgs = "All_Orgs"
  show (CNRS _) = "CNRS"
  show (IMT _) = "IMT"
  show (Others _) = "Others"

instance Read Org where
  read "All_Orgs" = Just $ All_Orgs
  read "CNRS" = Just $ CNRS $ Set.fromFoldable []
  read "IMT" = Just $ IMT $ Set.fromFoldable []
  read "Others" = Just $ Others $ Set.fromFoldable []
  read _ = Nothing

derive instance Eq Org
-- | NOTE: IMT_org list isn't really used here in JSON
-- | encoding. Instead, for HAL, a special query is constructed in
-- | SearchField (queryHAL function).
instance JSON.WriteForeign Org where
  writeImpl = JSON.writeImpl <<< show

------------------------------------------------------------------------
-- NOTE: IMT organizations are fetched via GraphQL from the backend

data IMT_org
  = All_IMT
  | IMT_org GQLIMT.School

derive instance Ord IMT_org
derive instance Eq IMT_org

instance Show IMT_org where
  show All_IMT = "All_IMT"
  show (IMT_org { school_shortName }) = school_shortName

instance Read IMT_org where
  read "All_IMT" = Just All_IMT
  read _ = Nothing

------------------------------------------------------------------------
data SearchOrder
  = DateAsc
  | DateDesc
  | TitleAsc
  | TitleDesc
  | ScoreAsc
  | ScoreDesc

instance Show SearchOrder where
  show DateAsc = "DateAsc"
  show DateDesc = "DateDesc"
  show TitleAsc = "TitleAsc"
  show TitleDesc = "TitleDesc"
  show ScoreAsc = "ScoreAsc"
  show ScoreDesc = "ScoreDesc"

------------------------------------------------------------------------

newtype SearchQuery = SearchQuery
  { query :: String
  , datafield :: DataField
  , files_id :: Array String
  , lang :: Maybe Lang
  , limit :: Maybe Int
  , node_id :: Maybe Int
  , offset :: Maybe Int
  , order :: Maybe SearchOrder
  , epoAPIUser :: Maybe String
  , epoAPIToken :: Maybe String
  , selection :: ListSelection.Selection
  }

derive instance Generic SearchQuery _
derive instance Newtype SearchQuery _
instance ToUrl Session SearchQuery where
  toUrl (Session { backend }) q = backendUrl backend q2
    where
    q2 = "new" <> Q.print (GT.toQuery q)

instance GT.ToQuery SearchQuery where
  toQuery (SearchQuery { offset, limit, order }) =
    QP.print id id $ QP.QueryPairs
      $ pair "offset" offset
      <> pair "limit" limit
      <> pair "order" order
    where
    pair :: forall a. Show a => String -> Maybe a -> Array (Tuple QP.Key (Maybe QP.Value))
    pair k = maybe [] $ \v ->
      [ QP.keyFromString k /\ Just (QP.valueFromString $ show v) ]

instance JSON.WriteForeign SearchQuery where
  writeImpl
    ( SearchQuery
        { datafield
        , lang
        , node_id
        , epoAPIUser
        , epoAPIToken
        , query
        , selection
        }
    ) =
    JSON.writeImpl
      { query: query -- String.replace (String.Pattern "\"") (String.Replacement "\\\"") query
      , datafield
      , lang: maybe "EN" show lang
      , node_id: fromMaybe 0 node_id
      , flowListWith: selection
      , epoAPIUser
      , epoAPIToken
      }

defaultSearchQuery :: SearchQuery
defaultSearchQuery = SearchQuery
  { query: ""
  , datafield: External Empty
  , files_id: []
  , lang: Nothing
  , limit: Nothing
  , node_id: Nothing
  , offset: Nothing
  , order: Nothing
  , epoAPIUser: Nothing
  , epoAPIToken: Nothing
  , selection: ListSelection.NoList -- MyListsFirst
  }

performSearch :: Session -> Int -> SearchQuery -> AffRESTError GAT.Task
performSearch session nodeId q = do
  post session p q
  -- eTask :: Either RESTError GT.AsyncTask <- post session p q
  -- pure $ (\task -> GAT.Task { task, typ: GT.Query }) <$> eTask
  where
  p = GR.NodeAPI GT.Corpus (Just nodeId) $ GT.asyncTaskTypePath GT.Query
