module Gargantext.Components.Forest.Tree.Node.Action.Search where

import Data.Maybe (Maybe(..))
import Effect (Effect)
import Effect.Aff (Aff, launchAff)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.App.Store as Store
import Gargantext.Components.Forest.Tree.Node.Action.Search.SearchBar (searchBar)
import Gargantext.Components.Forest.Tree.Node.Action.Search.SearchField (defaultSearch)
import Gargantext.Components.Forest.Tree.Node.Action.Types (Action(..))
import Gargantext.Components.Forest.Tree.Node.Action.Utils (loadLanguages)
import Gargantext.Components.Forest.Tree.Node.Tools as Tools
import Gargantext.Components.Lang (Lang)
import Gargantext.Hooks.Loader (useLoader)
import Gargantext.Prelude
import Gargantext.Sessions (Session)
import Gargantext.Types (ID)
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Record as Record
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.Search"

type Props =
  ( dispatch :: Action -> Aff Unit
  , id :: Maybe ID
  , session :: Session
  )

-- | Action : Search
actionSearch :: R2.Component Props
actionSearch = R.createElement actionSearchCpt

actionSearchCpt :: R.Component Props
actionSearchCpt = R2.hereComponent here "actionSearch" hCpt
  where
  hCpt hp props@({ session }) _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: loadLanguages
      , path: { session }
      , render: \langs ->
          actionSearchWithLangs (Record.merge props { langs }) []
      }

type PropsWithLangs =
  ( langs :: Array Lang
  | Props
  )

-- | Action : Search
actionSearchWithLangs :: R2.Component PropsWithLangs
actionSearchWithLangs = R.createElement actionSearchWithLangsCpt

actionSearchWithLangsCpt :: R.Component PropsWithLangs
actionSearchWithLangsCpt = here.component "actionSearchWithLangs" cpt
  where
  cpt { dispatch, id, langs, session } _ = do
    { errors } <- Store.use
    search <- T.useBox $ defaultSearch { node_id = id }
    pure $
      Tools.panelNoFooter
        { mError: Nothing
        , iconName: "search"
        , textTitle: "Search (APIs) and create a corpus"
        }
        -- H.div { className: "card-body toolbox-tab-content px-5 py-4" }
        [ H.div { className: "action-search mx-2" }
            [ H.text $ "Please fill the search query and choose a database."
            ]
        , searchBar
            { errors
            , langs
            , onSearch: searchOn dispatch
            , search
            , session
            }
            []
        ]
    where
    searchOn
      :: (Action -> Aff Unit)
      -> GAT.Task
      -> Effect Unit
    searchOn dispatch' task = do
      _ <- launchAff $ dispatch' (DoSearch task)
      -- close popup
      _ <- launchAff $ dispatch' CloseBox
      -- TODO
      --snd p $ const Nothing
      pure unit
