module Gargantext.Components.Forest.Tree.Node.Action.Search.SearchBar
  ( Props
  , searchBar
  ) where

import Effect (Effect)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.Forest.Tree.Node.Action.Search.SearchField (searchField)
import Gargantext.Components.Forest.Tree.Node.Action.Search.Types (Search, allDatabases)
import Gargantext.Components.Lang (Lang)
import Gargantext.Prelude (Unit, pure, ($))
import Gargantext.Sessions (Session)
import Gargantext.Types (FrontendError)
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.Search.SearchBar"

type Props =
  ( errors :: T.Box (Array FrontendError)
  , langs :: Array Lang
  , onSearch :: GAT.Task -> Effect Unit
  , search :: T.Box Search
  , session :: Session
  )

searchBar :: R2.Component Props
searchBar = R.createElement searchBarCpt

searchBarCpt :: R.Component Props
searchBarCpt = here.component "searchBar" cpt
  where
  cpt { errors, langs, onSearch, search, session } _ = do
    --onSearchChange session s
    pure $ H.form { className: "search-bar m-2" }
      [ searchField
          { databases: allDatabases
          , errors
          , langs
          , onSearch
          , search
          , session
          }
          []
      ]
