module Gargantext.Config.Utils where

import Gargantext.Prelude

import Data.Array as A
import Data.Either (Either(..))
import Data.Foldable (foldl)
import Data.Maybe (fromMaybe, Maybe(..))
import Effect (Effect)
import Effect.Aff (Aff)
import Effect.Class (liftEffect)
import Gargantext.Config.REST (RESTError, logRESTError)
import Gargantext.Types (AsyncEvent(..), AsyncTaskLog(..), FrontendError(..), asyncTaskLogEventsErrorMessage)
import Gargantext.Utils.Reactix as R2
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Config.Utils"

handleRESTError :: forall a.
                   R2.HerePrefix
                -> T.Box (Array FrontendError)
                -> Either RESTError a
                -> (a -> Aff Unit)
                -> Aff Unit
handleRESTError herePrefix errors (Left error) _ = liftEffect $ do
  T.modify_ (A.cons $ FRESTError { error }) errors
  logRESTError herePrefix error
  -- here.warn2 "[handleTaskError] RESTError" error
handleRESTError _ _ (Right task) handler = handler task

-- handleErrorInAsyncProgress :: T.Box (Array FrontendError)
--                            -> AsyncProgress
--                            -> Effect Unit
-- handleErrorInAsyncProgress errors ap@(AsyncProgress { status: IsFailure }) = do
--   T.modify_ (A.cons $ FStringError { error: concatErrors ap }) errors
-- handleErrorInAsyncProgress errors ap@(AsyncProgress { log, status: IsFinished }) = do
--   if countFailed > 0 then
--     T.modify_ (A.cons $ FStringError { error: concatErrors ap }) errors
--   else
--     pure unit
--   where
--     countFailed = foldl (+) 0 $ (\(AsyncTaskLog { failed }) -> failed) <$> log
-- handleErrorInAsyncProgress _ _ = pure unit

-- concatErrors :: AsyncProgress -> String
-- concatErrors (AsyncProgress { error, log }) = foldl eventsErrorMessage (fromMaybe "" error) log
--   where
--     eventsErrorMessage acc atl = asyncTaskLogEventsErrorMessage atl <> "\n" <> acc


handleErrorInAsyncTaskLog :: T.Box (Array FrontendError)
                          -> AsyncTaskLog
                          -> Effect Unit
handleErrorInAsyncTaskLog errors atl =
  case asyncTaskLogEventsErrorMessage atl of
    Nothing -> pure unit
    Just error ->
      T.modify_ (A.cons $ FStringError { error }) errors
