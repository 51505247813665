module Gargantext.Components.Forest.Tree.Node.Action.Move
  ( moveNodeReq
  , moveNode
  ) where

import Gargantext.Prelude

import Data.Maybe (Maybe(..))
import Gargantext.Components.Forest.Tree.Node.Action.Types (Action(..))
import Gargantext.Components.Forest.Tree.Node.Tools as Tools
import Gargantext.Components.Forest.Tree.Node.Tools.SubTree (SubTreeParamsIn, subTreeView)
import Gargantext.Config.REST (AffRESTError)
import Gargantext.Routes (SessionRoute(..))
import Gargantext.Sessions (Session, put_)
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.Move"

moveNodeReq :: Session -> GT.ID -> GT.ID -> AffRESTError (Array GT.ID)
moveNodeReq session fromId toId =
  put_ session $ NodeAPI GT.Node (Just fromId) ("move/" <> show toId)

moveNode :: R2.Component SubTreeParamsIn
moveNode = R.createElement moveNodeCpt

moveNodeCpt :: R.Component SubTreeParamsIn
moveNodeCpt = here.component "moveNode" cpt
  where
  cpt { dispatch, id, nodeType, session, subTreeParams } _ = do
    action :: T.Box Action <- T.useBox (MoveNode { params: Nothing })

    pure $
      moveNode'
        { action
        , dispatch
        , id
        , nodeType
        , session
        , subTreeParams
        }
        []

type Props =
  ( action :: T.Box Action
  | SubTreeParamsIn
  )

-- @XXX re-render issue -> clone component
moveNode' :: R2.Component Props
moveNode' = R.createElement moveNodeCpt'

moveNodeCpt' :: R.Component Props
moveNodeCpt' = here.component "__clone__" cpt
  where
  cpt { dispatch, id, nodeType, session, subTreeParams, action } _ = do

    action' <- T.useLive T.unequal action

    let
      button = case action' of
        MoveNode { params } ->
          R2.fromMaybe params $
            \val -> Tools.submitButton
              { action: MoveNode { params: Just val }
              , dispatch
              }
        _ -> H.div {} []

    pure $
      Tools.panel
        { mError: Nothing
        , iconName: "arrows"
        , textTitle: "Move this node"
        }
        [ subTreeView
            { action
            , dispatch
            , id
            , nodeType
            , session
            , subTreeParams
            }
            []

        -- footer
        , button
        ]
