module Gargantext.Components.DocsTable.SubcorpusCreation where

import Gargantext.Prelude

import Effect (Effect)
import Gargantext.Hooks.StateRecord (useStateRecord)
import Gargantext.Components.Bootstrap as B
import Gargantext.Components.Bootstrap.Types (ButtonVariant(..), ComponentStatus(..), Variant(..))
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Toestand as T

type Props =
  ( callback :: String -> Boolean -> Effect Unit
  , query' :: String
  , onSubcorpusCreationPending' :: Boolean
  )

subcorpusCreation :: R2.Leaf Props
subcorpusCreation = R2.leaf component

component :: R.Component Props
component = R.hooksComponent "subcorpusCreation" cpt
  where
  cpt { query', onSubcorpusCreationPending', callback } _ = do
    { state, stateBox } <- useStateRecord (defaultData :: FormData)

    let
      onParentListCheckboxChange :: Boolean -> Effect Unit
      onParentListCheckboxChange value = T.modify_
        (\prev -> prev { reuseParentList = value })
        stateBox

    pure $ H.div {}
      [ H.div { className: "form-group" }
          [ H.label {} [ H.text $ "Creating subcorpus from query: " <> query' ]
          ]
      , H.div { className: "form-check" }
          [ B.formCheckbox
              { value: state.reuseParentList
              , callback: onParentListCheckboxChange
              }
          , H.label { className: "form-check-label" } [ H.text "Reuse parent list?" ]
          ]
      , B.button
          { callback: \_ -> callback query' state.reuseParentList
          , type: "submit"
          , variant: ButtonVariant Primary
          , status: if query' == "" then Disabled else if onSubcorpusCreationPending' then Deferred else Enabled
          }
          [ H.text "Create!" ]
      ]

type FormData = { reuseParentList :: Boolean }

defaultData :: FormData
defaultData =
  { reuseParentList: true
  }
