module Gargantext.Components.Notifications.Types where

import Control.Monad.Except.Trans (runExceptT)
import Data.Array as A
import Data.Either (Either(..))
import Data.Eq.Generic (genericEq)
import Data.FoldableWithIndex (foldlWithIndex, foldMapWithIndex)
import Data.Generic.Rep (class Generic)
import Data.Hashable (class Hashable, hash)
import Data.HashMap as HM
import Data.Maybe (Maybe(..), fromMaybe, isJust)
import Data.Show.Generic (genericShow)
import Data.Traversable (for, traverse)
import Data.Tuple (Tuple(..))
import Effect (Effect)
import Effect.Ref as Ref
import Effect.Timer (setTimeout)
import Effect.Var (($=))
import Effect.Var as Var
import Foreign as F
import Gargantext.Sessions.Types (Session(..))
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Prelude
import Reactix as R
import Simple.JSON as JSON
import Web.Socket.Event.MessageEvent as ME
import WebSocket as WS


here :: R2.Here
here = R2.here "Gargantext.Components.Notifications.Types"


type NodeId = Int
-- Data.UUID.UUID is not Hashable
type UUID = String

data Topic =
    UpdateWorkerProgress GT.WorkerTask
  | UpdateTree NodeId
derive instance Generic Topic _
instance Eq Topic where eq = genericEq
instance Show Topic where show = genericShow
instance Hashable Topic where
  hash t = hash $ show t
instance JSON.ReadForeign Topic where
  readImpl f = do
    { type: type_ } <- JSON.readImpl f :: F.F { type :: String }
    case type_ of
      "update_worker_progress" -> do
        { ji } <- JSON.readImpl f :: F.F { ji :: GT.WorkerTask }
        pure $ UpdateWorkerProgress ji
      "update_tree" -> do
        { node_id } <- JSON.readImpl f :: F.F { node_id :: NodeId }
        pure $ UpdateTree node_id
      s -> F.fail $ F.ErrorAtProperty "type" $ F.ForeignError $ "unknown Topic type: " <> s
instance JSON.WriteForeign Topic where
  writeImpl (UpdateWorkerProgress ji) = JSON.writeImpl { "type": "update_worker_progress"
                                                       , ji }
  writeImpl (UpdateTree node_id) = JSON.writeImpl { "type": "update_tree"
                                                  , node_id }

data WSRequest =
    WSSubscribe Topic
  | WSUnsubscribe Topic
  | WSAuthorize String
  | WSDeauthorize
derive instance Generic WSRequest _
instance Eq WSRequest where eq = genericEq
instance JSON.WriteForeign WSRequest where
  writeImpl (WSSubscribe topic) = JSON.writeImpl { request: "subscribe"
                                                 , topic }
  writeImpl (WSUnsubscribe topic) = JSON.writeImpl { request: "unsubscribe"
                                                   , topic }
  writeImpl (WSAuthorize token) = JSON.writeImpl { request: "authorize"
                                                 , token }
  writeImpl WSDeauthorize = JSON.writeImpl { request: "deauthorize" }


data Notification =
    NUpdateWorkerProgress GT.WorkerTask GT.AsyncTaskLog
  | NUpdateTree NodeId
derive instance Generic Notification _
instance JSON.ReadForeign Notification where
  readImpl f = do
    { type: type_ } <- JSON.readImpl f :: F.F { type :: String }
    case type_ of
      "update_worker_progress" -> do
        { job_info, job_log } <- JSON.readImpl f :: F.F { job_info :: GT.WorkerTask, job_log :: GT.AsyncTaskLog }
        pure $ NUpdateWorkerProgress job_info job_log
      "update_tree" -> do
        { node_id } <- JSON.readImpl f :: F.F { node_id :: NodeId }
        pure $ NUpdateTree node_id
      s -> F.fail $ F.ErrorAtProperty "type" $ F.ForeignError $ "unkown type: " <> s

notificationTopics :: Notification -> Array Topic
notificationTopics (NUpdateWorkerProgress workerTask@(GT.WorkerTask { node_id }) _) =
  [ UpdateWorkerProgress workerTask ] <> updateTree
  where
    -- when receiving a worker progress notification, we are also
    -- interested in 'update tree' subscriptions, because there might
    -- be a new job that we didn't subscribe to
    updateTree = case node_id of
      Nothing -> []
      Just nId -> [ UpdateTree nId ]
notificationTopics (NUpdateTree nodeId) = [ UpdateTree nodeId ]


type Callback = Notification -> Effect Unit

type CallbacksHM = HM.HashMap UUID Callback

data State =
  State { callbacks :: HM.HashMap Topic CallbacksHM }

emptyState :: State
emptyState = State { callbacks : HM.empty }



data WSNotification =
  WSNotification { state :: Ref.Ref State

                 -- TODO Implement a WS connection
                 , connection :: Ref.Ref (Maybe WS.Connection)
                   
                 -- This calls R.setRef :: R.Ref State -> Effect Unit
                 -- , insertCallback :: Topic -> UUID -> Effect Unit
                 -- This calls R.setRef :: R.Ref State -> Effect Unit
                 -- , removeCallback :: Topic -> UUID -> Effect Unit
                 }

emptyWSNotification :: Effect WSNotification
emptyWSNotification = do
  state <- Ref.new emptyState
  connection <- Ref.new Nothing
  pure $ WSNotification { state
                        , connection }



-- | Actions to be called on the websocket connection
data Action =
    InsertCallback Topic UUID Callback
  | RemoveCallback Topic UUID
  | Call Notification
