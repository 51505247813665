module Gargantext.Components.Forest.Tree.Node.Action.Search.SearchField where

import Gargantext.Prelude

import Data.Array as A
import Data.Maybe (Maybe(..), maybe, fromMaybe)
import Data.Newtype (over)
import Data.Nullable (null)
import Data.Set as Set
import Data.String.Common (joinWith)
import Data.Tuple (Tuple(..))
import Effect (Effect)
import Effect.Aff (launchAff_)
import Effect.Class (liftEffect)
import Gargantext.AsyncTasks as GAT
import Gargantext.Components.Forest.Tree.Node.Action.Search.Frame (searchIframes)
import Gargantext.Components.Forest.Tree.Node.Action.Search.Types (DataField(..), Database(..), IMT_org(..), Org(..), SearchQuery(..), allOrgs, dataFields, defaultSearchQuery, doc, performSearch, datafield2database, Search, dbFromInputValue, dbToInputValue)
import Gargantext.Components.GraphQL.Endpoints (getIMTSchools, getUser)
import Gargantext.Components.GraphQL.IMT as GQLIMT
import Gargantext.Components.InputWithEnter (inputWithEnter)
import Gargantext.Components.Lang (Lang(..))
import Gargantext.Components.ListSelection as ListSelection
import Gargantext.Components.ListSelection.Types as ListSelection
import Gargantext.Config.Utils (handleRESTError)
import Gargantext.Hooks.Loader (useLoader)
import Gargantext.Sessions (Session(..))
import Gargantext.Types (FrontendError)
import Gargantext.Types as GT
import Gargantext.Utils.Reactix as R2
import Reactix as R
import Reactix.DOM.HTML as H
import Toestand as T

here :: R2.Here
here = R2.here "Gargantext.Components.Forest.Tree.Node.Action.Search.SearchField"

defaultSearch :: Search
defaultSearch =
  { datafield: External Empty
  , node_id: Nothing
  , lang: Nothing
  , term: ""
  , url: ""
  , years: []
  }

type Props =
  -- list of databases to search, or parsers to use on uploads
  ( databases :: Array Database
  , errors :: T.Box (Array FrontendError)
  , langs :: Array Lang
  -- State hook for a search, how we get data in and out
  , onSearch :: GAT.Task -> Effect Unit
  , search :: T.Box Search
  , session :: Session
  )

searchField :: R2.Component Props
searchField = R.createElement searchFieldCpt

searchFieldCpt :: R.Component Props
searchFieldCpt = here.component "searchField" cpt
  where
  cpt { databases, errors, langs, onSearch, search, session } _ = do
    selection <- T.useBox ListSelection.MyListsFirst

    pure $
      H.div { className: "search-field" }
        [ searchInput { search } []
        -- , if length s.term < 3  -- search with love : <3
        --   then
        --     H.div {}[]
        --   else
        , datafieldInput { databases, langs, search, session } []
        , ListSelection.selection { selection, session } []
        , submitButton { errors, onSearch, search, selection, session } []
        ]

--pure $ panel params button

type ComponentProps =
  (search :: T.Box Search)

componentYears :: R2.Component ComponentProps
componentYears = R.createElement componentYearsCpt

componentYearsCpt :: R.Component ComponentProps
componentYearsCpt = here.component "componentYears" cpt
  where
  cpt { search } _ = do
    { years } <- T.useLive T.unequal search
    let yearsZ = A.zip (A.range 0 (A.length years)) years
    newYear <- T.useBox ""
    pure $ H.div {}
      ( (yearCpt <$> yearsZ) <>
          [ H.div {}
              [ H.input
                  { on:
                      { blur: modify newYear
                      , change: modify newYear
                      , input: modify newYear
                      }
                  }
              , H.span
                  { className: "btn btn-primary fa fa-check"
                  , on: { click: clickAdd newYear }
                  }
                  []
              ]
          ]
      )
    where
    clickAdd newYear _ = do
      newYear' <- T.read newYear
      T.modify_ (\s@{ years } -> s { years = A.snoc years newYear' }) search
    clickRemove idx _ =
      T.modify_ (\s@{ years } -> s { years = left idx years <> right (A.length years - idx) years }) search
      where
      left 0 _years = []
      left idx' years = A.take idx' years
      right 0 _years = []
      right len years = A.takeEnd (len - 1) years
    modify newYear e = T.write_ (R.unsafeEventValue e) newYear
    yearCpt (Tuple idx year) =
      H.div {}
        [ H.span {} [ H.text year ]
        , H.span
            { className: "btn btn-danger fa fa-times"
            , on: { click: clickRemove idx search }
            }
            []
        ]

type ComponentIMTProps =
  ( session :: Session
  | ComponentProps
  )

componentIMT :: R2.Component ComponentIMTProps
componentIMT = R.createElement componentIMTCpt

componentIMTCpt :: R.Component ComponentIMTProps
componentIMTCpt = R2.hereComponent here "componentIMT" hCpt
  where
  hCpt hp { search, session } _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: \_ -> getIMTSchools session
      , path: unit
      , render: \schools -> componentWithIMTOrgs { schools, search } []
      }

type ComponentWithIMTOrgsProps =
  ( schools :: Array GQLIMT.School
  , search :: T.Box Search
  )

componentWithIMTOrgs :: R2.Component ComponentWithIMTOrgsProps
componentWithIMTOrgs = R.createElement componentWithIMTOrgsCpt

componentWithIMTOrgsCpt :: R.Component ComponentWithIMTOrgsProps
componentWithIMTOrgsCpt = here.component "componentWithIMTOrgs" cpt
  where
  cpt { schools, search } _ = do
    search' <- T.useLive T.unequal search

    let
      allIMTOrgs = [ All_IMT ] <> (IMT_org <$> schools)
      liCpt org =
        H.li {}
          [ H.input
              { type: "checkbox"
              , checked: isIn org search'.datafield
              , on:
                  { change: \_ -> (T.modify_ (_ { datafield = updateFilter org allIMTOrgs search'.datafield }) search)
                  }
              }
          , case org of
              All_IMT -> H.i {} [ H.text $ " " <> show org ]
              (IMT_org { school_shortName }) -> H.text $ " " <> school_shortName
          ]

    pure $ R.fragment
      [ H.ul {} $ map liCpt $ allIMTOrgs
      --, filterInput fi
      ]

componentCNRS :: R2.Component ComponentProps
componentCNRS = R.createElement componentCNRSCpt

componentCNRSCpt :: R.Component ComponentProps
componentCNRSCpt = here.component "componentCNRS" cpt
  where
  cpt _ _ = do
    pure $ R.fragment
      [ H.div {} []
      --, filterInput fi
      ]

isExternal :: DataField -> Boolean
isExternal (External _) = true
isExternal _ = false

isArxiv :: DataField -> Boolean
isArxiv (External Arxiv) = true
isArxiv _ = false

isHAL :: DataField -> Boolean
isHAL (External (HAL _)) = true
isHAL _ = false

isIsTex :: DataField -> Boolean
isIsTex (External (IsTex)) = true
isIsTex _ = false

isIMT :: DataField -> Boolean
isIMT
  ( External
      ( HAL
          (Just (IMT _))
      )
  ) = true
isIMT _ = false

isCNRS :: DataField -> Boolean
isCNRS
  ( External
      ( HAL
          (Just (CNRS _))
      )
  ) = true
isCNRS _ = false

isPubmed :: DataField -> Boolean
isPubmed (External (PubMed _)) = true
isPubmed _ = false

getPubmedAPIKey :: DataField -> Maybe String
getPubmedAPIKey (External (PubMed mAPI)) = mAPI
getPubmedAPIKey _ = Nothing

isEPO :: DataField -> Boolean
isEPO (External (EPO _ _)) = true
isEPO _ = false

needsLang :: DataField -> Boolean
needsLang Gargantext = true
needsLang Web = true
needsLang (External (HAL _)) = true
needsLang _ = false

isIn :: IMT_org -> DataField -> Boolean
isIn
  org
  ( External
      ( HAL
          ( Just
              (IMT imtOrgs)
          )
      )
  ) = Set.member org imtOrgs
isIn _ _ = false

updateFilter :: IMT_org -> Array IMT_org -> DataField -> DataField
updateFilter org allIMTorgs (External (HAL (Just (IMT imtOrgs)))) =
  External $ HAL $ Just $ IMT imtOrgs'
  where
  imtOrgs' =
    if Set.member org imtOrgs then
      if org == All_IMT then Set.empty
      else Set.delete All_IMT $ Set.delete org imtOrgs
    else if org == All_IMT then Set.fromFoldable allIMTorgs
    else Set.insert org imtOrgs

updateFilter org allIMTorgs _ = (External (HAL (Just (IMT imtOrgs'))))
  where
  imtOrgs' =
    if org == All_IMT then Set.fromFoldable allIMTorgs
    else Set.fromFoldable [ org ]

------------------------------------------------------------------------

type LangNavProps =
  ( langs :: Array Lang
  , search :: T.Box Search
  )

langNav :: R2.Component LangNavProps
langNav = R.createElement langNavCpt

langNavCpt :: R.Component LangNavProps
langNavCpt = here.component "langNav" cpt
  where
  cpt { langs, search } _ = do
    search' <- T.useLive T.unequal search

    pure $ R.fragment
      [ H.div { className: "text-primary center p-1" } [ H.text "with lang" ]
      , H.div { className: "nav nav-tabs p-1" } ((liItem search') <$> langs)
      ]
    where
    liItem :: Search -> Lang -> R.Element
    liItem { lang } lang' =
      H.div
        { className: "nav-item nav-link" <> if (Just lang') == lang then " active" else ""
        , on: { click: \_ -> T.modify_ (_ { lang = Just lang' }) search }
        }
        [ H.text (show lang') ]

------------------------------------------------------------------------

type DataFieldNavProps =
  (search :: T.Box Search)

dataFieldNav :: R2.Component DataFieldNavProps
dataFieldNav = R.createElement dataFieldNavCpt

dataFieldNavCpt :: R.Component DataFieldNavProps
dataFieldNavCpt = here.component "dataFieldNav" cpt
  where
  cpt { search } _ = do
    search'@{ datafield } <- T.useLive T.unequal search

    pure $ R.fragment
      [ H.div { className: "text-primary text-bold p-1" } [ H.text "Search options:" ]
      , H.div { className: "nav nav-tabs" } ((liItem search') <$> dataFields)
      , H.div { className: "center p-1" }
          [ H.text $ doc datafield
          ]
      ]
    where
    liItem :: Search -> DataField -> R.Element
    liItem { datafield } df' =
      H.div
        { className: "nav-item nav-link"
            <>
              if isActive --(Just df') == datafield
              then " active"
              else ""
        , on:
            { click: \_ -> T.modify_
                ( _
                    { datafield = df'
                    }
                )
                search
            }
        -- just one database query for now
        -- a list a selected database needs more ergonomy
        }
        [ H.text (show df') ]
      where
      isActive = show (Just df') == show datafield

------------------------------------------------------------------------

type DatabaseInputProps =
  ( databases :: Array Database
  , search :: T.Box Search
  )

databaseInput :: R2.Component DatabaseInputProps
databaseInput = R.createElement databaseInputCpt

databaseInputCpt :: R.Component DatabaseInputProps
databaseInputCpt = here.component "databaseInput" cpt
  where
  cpt
    { databases
    , search
    }
    _ = do
    search' <- T.useLive T.unequal search

    let
      db = case search'.datafield of
        (External x) -> Just x
        _ -> Nothing

      dbInputValue = fromMaybe "" $ dbToInputValue <$> db

      liItem :: Database -> R.Element
      liItem db' = H.option
        { className: "text-primary center"
        , value: dbToInputValue db'
        }
        [ H.text (show db') ]

      change e = do
        let value = dbFromInputValue $ R.unsafeEventValue e

        let updatedValue = fromMaybe Empty value

        T.modify_
          ( _
              { datafield = External updatedValue
              }
          )
          search

    pure $
      H.div { className: "form-group p-1 mb-0" }
        [ H.div { className: "text-primary center" } [ H.text "in database" ]
        , R2.select
            { className: "form-control"
            , defaultValue: dbInputValue
            , on: { change }
            }
            (liItem <$> databases)
        , H.div { className: "center p-1" } [ H.text $ maybe "" doc db ]
        ]

type PubmedInputProps =
  ( search :: T.Box Search
  , session :: Session
  )

pubmedInput :: R2.Component PubmedInputProps
pubmedInput = R.createElement pubmedInputCpt

pubmedInputCpt :: R.Component PubmedInputProps
pubmedInputCpt = R2.hereComponent here "pubmedInput" hCpt
  where
  hCpt hp { search, session: session@(Session { treeId }) } _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: \_ -> getUser session treeId
      , path: unit
      , render: \user -> pubmedInputLoaded
          { pubmedAPIKey: user.u_hyperdata.pubmed_api_key
          , search
          }
          []
      }

type PubmedInputLoadedProps =
  ( pubmedAPIKey :: Maybe String
  , search :: T.Box Search
  )

pubmedInputLoaded :: R2.Component PubmedInputLoadedProps
pubmedInputLoaded = R.createElement pubmedInputLoadedCpt

pubmedInputLoadedCpt :: R.Component PubmedInputLoadedProps
pubmedInputLoadedCpt = here.component "pubmedInputLoaded" cpt
  where
  cpt { pubmedAPIKey, search } _ = do
    search' <- T.useLive T.unequal search

    R.useEffectOnce' $ do
      when (getPubmedAPIKey search'.datafield /= pubmedAPIKey) $ do
        T.modify_ (\s -> s { datafield = External $ PubMed pubmedAPIKey }) search
    -- T.write_ (search' { pubmedApiKey = pubmedAPIKey }) search

    pure $
      H.div { className: "form-group p-1 m-0" }
        [ H.div { className: "text-primary center" } [ H.text "Pubmed API key" ]
        , H.input
            { className: "form-control"
            , defaultValue: fromMaybe "" pubmedAPIKey
            , on:
                { blur: modifyPubmedAPIKey search
                , change: modifyPubmedAPIKey search
                , input: modifyPubmedAPIKey search
                }
            }
        ]
    where
    modifyPubmedAPIKey searchS e = do
      let val = R.unsafeEventValue e
      let
        mVal = case val of
          "" -> Nothing
          s -> Just s
      T.modify_
        ( \s ->
            s { datafield = External $ PubMed mVal }
        )
        searchS

type EPOInputProps =
  ( search :: T.Box Search
  , session :: Session
  )

epoInput :: R2.Component EPOInputProps
epoInput = R.createElement epoInputCpt

epoInputCpt :: R.Component EPOInputProps
epoInputCpt = R2.hereComponent here "epoInput" hCpt
  where
  hCpt hp { search, session: session@(Session { treeId }) } _ = do
    useLoader
      { errorHandler: Nothing
      , herePrefix: hp
      , loader: \_ -> getUser session treeId
      , path: unit
      , render: \user -> epoInputLoaded
          { epoAPIUser: user.u_hyperdata.epo_api_user
          , epoAPIToken: user.u_hyperdata.epo_api_token
          , search
          }
          []
      }

type EPOInputLoadedProps =
  ( epoAPIUser :: Maybe String
  , epoAPIToken :: Maybe String
  , search :: T.Box Search
  )

searchEPOAPIUser :: Search -> Maybe String
searchEPOAPIUser { datafield: External (EPO mAPIUser _) } = mAPIUser
searchEPOAPIUser _ = Nothing

searchEPOAPIToken :: Search -> Maybe String
searchEPOAPIToken { datafield: External (EPO _ mAPIToken) } = mAPIToken
searchEPOAPIToken _ = Nothing

epoInputLoaded :: R2.Component EPOInputLoadedProps
epoInputLoaded = R.createElement epoInputLoadedCpt

epoInputLoadedCpt :: R.Component EPOInputLoadedProps
epoInputLoadedCpt = here.component "epoInputLoaded" cpt
  where
  cpt { epoAPIUser, epoAPIToken, search } _ = do
    search' <- T.useLive T.unequal search

    R.useEffectOnce' $ do
      when (searchEPOAPIUser search' /= epoAPIUser || searchEPOAPIToken search' /= epoAPIToken) $ do
        T.write_
          ( search'
              { datafield = External (EPO epoAPIUser epoAPIToken)
              }
          )
          search

    pure $
      H.div { className: "form-group p-1 m-0" }
        [ H.div { className: "text-primary center" } [ H.text "EPO API User" ]
        , H.input
            { className: "form-control"
            , defaultValue: fromMaybe "" $ searchEPOAPIUser search'
            , on:
                { blur: modifyEPOAPIUser search
                , change: modifyEPOAPIUser search
                , input: modifyEPOAPIUser search
                }
            }
        , H.div { className: "text-primary center" } [ H.text "EPO API Token" ]
        , H.input
            { className: "form-control"
            , defaultValue: fromMaybe "" $ searchEPOAPIToken search'
            , on:
                { blur: modifyEPOAPIToken search
                , change: modifyEPOAPIToken search
                , input: modifyEPOAPIToken search
                }
            }
        ]
    where
    modifyEPOAPIUser searchS e = do
      let val = R.unsafeEventValue e
      let
        mVal = case val of
          "" -> Nothing
          s -> Just s
      T.modify_ (\s -> s { datafield = External (EPO mVal (searchEPOAPIToken s)) }) searchS

    modifyEPOAPIToken searchS e = do
      let val = R.unsafeEventValue e
      let
        mVal = case val of
          "" -> Nothing
          s -> Just s
      T.modify_ (\s -> s { datafield = External (EPO (searchEPOAPIUser s) mVal) }) searchS

type OrgInputProps =
  ( orgs :: Array Org
  | ComponentProps
  )

orgInput :: R2.Component OrgInputProps
orgInput = R.createElement orgInputCpt

orgInputCpt :: R.Component OrgInputProps
orgInputCpt = here.component "orgInput" cpt
  where
  cpt { orgs, search } _ = do
    let
      change e = do
        let value = R.unsafeEventValue e
        T.modify_ (_ { datafield = External $ HAL $ read value }) search

    pure $ H.div { className: "form-group p-1 m-0" }
      [ H.div { className: "text-primary center" } [ H.text "filter with organization: " ]
      , R2.select
          { className: "form-control"
          , on: { change }
          }
          (liItem <$> orgs)
      ]

  liItem :: Org -> R.Element
  liItem org = H.option { className: "text-primary center" } [ H.text (show org) ]

{-
filterInput :: R.State String -> R.Element
filterInput (term /\ setTerm) =
  H.div { className: "form-group" }
        [ H.input { defaultValue: term
                  , className: "form-control"
                  , type: "text"
                  , on: { change: setTerm <<< const <<< R.unsafeEventValue }
                  , "required pattern": "[[0-9]+[ ]+]*"
                  -- TODO                          ^FIXME not sure about the regex comprehension: that should match "123 2334 44545" only (Integers separated by one space)
                  -- form validation with CSS
                  -- DOC: https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Form_validation
                  , placeholder : "Filter with struct_Ids as integer"
                  }
         ]
-}

type DatafieldInputProps =
  ( databases :: Array Database
  , langs :: Array Lang
  , search :: T.Box Search
  , session :: Session
  )

datafieldInput :: R2.Component DatafieldInputProps
datafieldInput = R.createElement datafieldInputCpt

datafieldInputCpt :: R.Component DatafieldInputProps
datafieldInputCpt = here.component "datafieldInput" cpt
  where
  cpt { databases, langs, search, session } _ = do
    search' <- T.useLive T.unequal search
    iframeRef <- R.useRef null

    pure $ H.div {}
      [ dataFieldNav { search } []

      , if isExternal search'.datafield then databaseInput { databases, search } []
        else H.div {} []

      , if isPubmed search'.datafield then pubmedInput { search, session } []
        else H.div {} []

      , if isEPO search'.datafield then epoInput { search, session } []
        else H.div {} []

      , if isHAL search'.datafield then orgInput { orgs: allOrgs, search } []
        else H.div {} []

      , if isIMT search'.datafield then componentIMT { search, session } []
        else H.div {} []

      -- , if isHAL search'.datafield
      --   then componentYears { search } []
      --   else H.div {} []

      , if isCNRS search'.datafield then componentCNRS { search } []
        else H.div {} []

      , if needsLang search'.datafield then
          if search'.datafield == Web then
            langNav { langs: A.filter (\lang -> lang == FR) langs, search } []
          else
            langNav { langs, search } []
        else
          H.div {} []

      , H.div {} [ searchIframes { iframeRef, search } [] ]
      ]

type SearchInputProps =
  ( search :: T.Box Search
  )

searchInput :: R2.Component SearchInputProps
searchInput = R.createElement searchInputCpt

searchInputCpt :: R.Component SearchInputProps
searchInputCpt = here.component "searchInput" cpt
  where
  cpt { search } _ = do
    { term } <- T.useLive T.unequal search
    valueRef <- R.useRef term

    pure $ H.div { className: "" }
      [ inputWithEnter
          { onBlur: onBlur valueRef search
          , onEnter: onEnter valueRef search
          , onValueChanged: onValueChanged valueRef
          , autoFocus: true
          , className: "form-control"
          , defaultValue: R.readRef valueRef
          , placeholder: "Your query here"
          , type: "text"
          , required: true
          }
      ]

  -- pure $
  --   H.div { className : "" }
  --   [ H.input { className: "form-control"
  --             , defaultValue: search.term
  --             , on: { input : onInput valueRef setSearch }
  --             , placeholder: "Your Query here"
  --             , type: "text"
  --             }
  --   ]
  onBlur valueRef search value = do
    R.setRef valueRef value
    T.modify_ (_ { term = value }) search
  onEnter valueRef search _ = do
    T.modify_ (_ { term = R.readRef valueRef }) search

  onValueChanged valueRef value = do
    R.setRef valueRef value

-- setSearch $ _ { term = value }

type SubmitButtonProps =
  ( errors :: T.Box (Array FrontendError)
  , onSearch :: GAT.Task -> Effect Unit
  , search :: T.Box Search
  , selection :: T.Box ListSelection.Selection
  , session :: Session
  )

submitButton :: R2.Component SubmitButtonProps
submitButton = R.createElement submitButtonComponent

submitButtonComponent :: R.Component SubmitButtonProps
submitButtonComponent = here.component "submitButton" cpt
  where
  cpt { errors, onSearch, search, selection, session } _ = do
    search' <- T.useLive T.unequal search
    selection' <- T.useLive T.unequal selection

    pure $
      H.button
        { className: "btn btn-primary"
        , "type": "button"
        , on: { click: doSearch onSearch errors session selection' search' }
        , style: { width: "100%" }
        }
        [ H.text "Launch Search" ]

  doSearch onSearch errors session selection search = \_ -> do
    -- log2 "[submitButton] searching" search
    triggerSearch { onSearch, errors, session, selection, search }

--case search.term of
--  "" -> setSearch $ const defaultSearch
--  _  -> setSearch $ const q

type TriggerSearch =
  ( errors :: T.Box (Array FrontendError)
  , onSearch :: GAT.Task -> Effect Unit
  , search :: T.Box Search
  , selection :: T.Box ListSelection.Selection
  , session :: Session
  )

triggerSearch
  :: { onSearch :: (GAT.Task -> Effect Unit)
     , errors :: T.Box (Array FrontendError)
     , session :: Session
     , selection :: ListSelection.Selection
     , search :: Search
     }
  -> Effect Unit
triggerSearch { onSearch, errors, session, selection, search } =
  launchAff_ $ do
    -- liftEffect $ do
    -- let here' = "[triggerSearch] Searching "
    -- here.log2 (here' <> "databases: ") (show search.databases)
    -- here.log2 (here' <> "datafield: ") (show search.datafield)
    -- here.log2 (here' <> "term: ")            search.term
    -- here.log2 (here' <> "lang: ")      (show search.lang)

    case search.node_id of
      Nothing -> liftEffect $ here.log "[triggerSearch] node_id is Nothing, don't know what to do"
      Just id -> do
        -- liftEffect $ here.log2 "[triggerSearch] searchQuery" $ searchQuery selection search
        eTask <- performSearch session id $ searchQuery selection search
        handleRESTError (R2.herePrefix here "[triggerSearch]") errors eTask $
          \task -> liftEffect $ do
            -- here.log2 "[triggerSearch] task" task
            onSearch task

--liftEffect $ do
--  log2 "Return:" r
--  modalShow "addCorpus"

searchQuery :: ListSelection.Selection -> Search -> SearchQuery
-- TODO Simplify both HAL Nothing and HAL (Just IMT) cases
searchQuery
  selection
  { datafield: datafield@(External (HAL Nothing))
  , lang
  , node_id
  , term
  , years
  } =
  over SearchQuery
    ( _
        { datafield = datafield
        , lang = lang
        , node_id = node_id
        , query = queryHAL term Nothing lang years
        , selection = selection
        }
    )
    defaultSearchQuery

searchQuery
  selection
  { datafield: datafield@(External (HAL (Just (IMT imtOrgs))))
  , lang
  , node_id
  , term
  , years
  } =
  over SearchQuery
    ( _
        { datafield = datafield
        , lang = lang
        , node_id = node_id
        , query = queryHAL term (Just imtOrgs) lang years
        , selection = selection
        }
    )
    defaultSearchQuery
searchQuery selection { datafield, lang, term, node_id } =
  over SearchQuery
    ( _
        { datafield = datafield
        , lang = lang
        , node_id = node_id
        , query = term
        , selection = selection
        }
    )
    defaultSearchQuery

queryHAL :: String -> Maybe (Set.Set IMT_org) -> Maybe Lang -> Array String -> String
queryHAL term mIMTOrgs lang years =
  joinWith " AND " $ filterOutEmptyString
    [ titleAbstract
    , structQuery
    , yearQuery
    ]
  where
  -- this uses solr query syntax
  -- https://yonik.com/solr/query-syntax/
  titleAbstract = case term of
    "" -> ""
    _ -> "(" <> langPrefix <> "_title_t:" <> termMulti
      <> " OR "
      <> langPrefix
      <> "_abstract_t:"
      <> termMulti
      <> ")"
  langPrefix = case lang of
    Just FR -> "fr"
    _ -> "en"
  -- TODO: Escape double quotes
  --termEscaped = "\"" <> (replaceAll (Pattern "\"") (Replacement "\\\"") term) <> "\""
  -- termEscaped = "\"" <> term <> "\""
  termMulti = "(" <> term <> ")"
  structQuery = case mIMTOrgs of
    Nothing -> ""
    Just imtOrgs ->
      if Set.isEmpty imtOrgs then
        ""
      else
        " (" <> (structIds imtOrgs) <> ")"
  yearQuery = joinWith " AND " $ (\year -> "producedDateY_i:" <> year) <$> years

  joinFunc :: IMT_org -> String
  joinFunc All_IMT = ""
  joinFunc (IMT_org { school_id }) = "structId_i:" <> school_id

  structIds :: Set.Set IMT_org -> String
  structIds imtOrgs = joinWith " OR " $ filterOutEmptyString $ joinFunc <$> Set.toUnfoldable imtOrgs
  filterOutEmptyString = A.filter (_ /= "")
